import React from "react";
import styled from "styled-components";

export const Button = ({ variant = "", className = "", ...others }) => {
  const variantClsName = variant ? `btn-default-${variant}` : "btn-default";

  return (
    <StyledButton
      className={`flex ff-serif pointer btn ${variantClsName} ${className}`}
      {...others}
      variant={variant}
    />
  );
};

const StyledButton = styled.button.attrs(() => {})`
  --gap: 0.2rem;
  padding: 0.3em 1em;
  align-items: center;
  justify-content: space-around;
  transition: 300ms;

  svg > path {
    stroke: ${(props) => (props.variant ? "" : "hsla(var(--clr-light))")};
  }

  &:hover {
    background-color: ${(props) =>
      props.variant ? "" : "hsla(var(--clr-blue-tint-300))"};
    color: ${(props) =>
      props.variant
        ? "hsla(var(--clr-blue-tint-300));"
        : "hsla(var(--clr-gray-50))"};
    border-color: ${(props) =>
      props.variant ? "hsla(var(--clr-blue-tint-300))" : ""};
    svg > path {
      stroke: ${(props) =>
        props.variant
          ? "hsla(var(--clr-blue-tint-300))"
          : "hsla(var(--clr-light))"};
    }
  }
  &:disabled {
    cursor: not-allowed;
    background: ${(props) =>
      props.variant ? "" : "hsla(var(--clr-blue-tint-900))"};
    border-color: ${(props) =>
      props.variant
        ? "hsla(var(--clr-black-tint-100))"
        : " hsla(var(--clr-blue-tint-900))"};
    color: hsla(var(--clr-black-tint-100));
  }
`;
