import React, { Component } from 'react';
import SolidBtn from '../common/SolidBtn';
import ClearBtn from '../common/ClearBtn';
import Modal from '../common/Modal';
import Loader from '../common/Loader';
import { connect } from "react-redux";
import { fetchAllSku, addSku, deleteSku, searchSku } from "../../actions/config";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBan, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(
    faSearch,
    faBan
)

class SkuSettings extends Component {
    state = {
        show: false,
        sku: [],
        offset: 0,
        searchKey: ''
    }

    async componentDidMount() {
        this.setState({
            offset: 0
        })
        await this.props.fetchAllSku(0);
        if (this.props.data) {
            this.setState({
                sku: this.props.data.data,
            })
        }
    }

    more = async () => {
        this.setState({
            offset: this.state.offset + 10,
        })
        if(this.state.searchKey !== ''){
            await this.props.searchSku(this.state.offset, this.state.searchKey);
        } else {
            await this.props.fetchAllSku(this.state.offset);
        }
        if (this.props.fulfilled) {
            return this.setState({
                sku: this.state.sku.concat(this.props.data.data),
            })
        }
    }

    reload = () => {
        this.componentDidMount()
    }

    openModal = () => {
        this.setState({
            show: true
        })
    }

    closeModal = () => {
        this.setState({
            show: false
        })
    }

    handleChange = (name, e) => {
        this.setState({
            [name]: e.target.value,
        });
    };

    handleAdd = async () => {
        const { item_no, item_name } = this.state;
        if (!item_no || !item_name) {
            return;
        }
        this.setState({
            show: false
        })
        await this.props.addSku({ "item_no": item_no, "item_name": item_name });

        if (this.props.newSku) {
            return this.componentDidMount();
        }
    };

    handleDelete = async (item_no, item_name) => {
        await this.props.deleteSku(item_no, item_name);

        if (this.props.deletedSku) {
            return this.componentDidMount();
        }
    };

    handleSearch = async (e) => {
        this.setState({
            searchKey: e.target.value
        })
        if (e.target.value === '') {
            return this.componentDidMount();
        }
        await this.props.searchSku(this.state.offset, e.target.value);
        if (this.props.fulfilled) {
            return this.setState({
                sku: this.props.data.data,
            })
        }
    }

    render() {
        const { show, sku } = this.state;
        return (
            <div id='settings'>
                <div className='main'>
                    <div className='top-settings'>
                        <div className='top-left'>
                            <SolidBtn text='ADD SKU' click={this.openModal} />
                        </div>
                        <div className='top-right'>
                            <div className='search-container'>
                                <FontAwesomeIcon icon="search" className='search-icon' />
                                <input type="text" id="search" name="search" placeholder="Item Name, Item No" className='search' onChange={text => this.handleSearch(text)} />
                            </div>
                        </div>
                    </div>
                    {/* <div style="overflow-x:auto;"> */}
                    <div className='table-container'>
                        <table className='table-header'>
                            <tbody>
                                <tr>
                                    <th>S|N</th>
                                    <th>Item Number</th>
                                    <th>Item Name</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </tbody>
                        </table>
                        {
                            sku.length > 0 &&
                            <table className='table-body'>
                                <tbody>
                                    {
                                        sku.map((item, index) =>
                                            <tr key={index}>
                                                <td></td>
                                                <td>{item.id}</td>
                                                <td>{item.item_name}</td>
                                                <td>{item.status === true ? "Active" : "Not Active"}</td>
                                                <td>
                                                    <div className='edit-container' onClick={() => this.handleDelete(item.id, item.item_name)}>
                                                        <FontAwesomeIcon icon='ban' className='pencil' />
                                                        <p>Deactivate</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        }
                        {
                            !this.props.loading && sku.length < 1 &&
                            <div className='notLoaded'>
                                <p>No response</p>
                                <ClearBtn text='RELOAD' click={this.reload} />
                            </div>
                        }
                        {
                            this.props.loading &&
                            <Loader />
                        }
                    </div>
                    {
                        !this.props.loading && sku.length > 9 &&
                        <div className='more'>
                            {
                                this.props.fulfilled ?
                                    <ClearBtn text='LOAD MORE' click={this.more} /> :
                                    <p>End of list</p>
                            }
                        </div>
                    }
                </div>
                <Modal show={show} handleClose={this.closeModal} style={"modal-main"}>
                    <div id="popup">
                        <div className='modal-top'>
                            <p>Add a new sku</p>
                        </div>
                        <div className='modal-body'>
                            <p>Fill in the details below to add a new Sku</p>
                            <div>
                                <div className='inline'>
                                    <label htmlFor="itemNo">Item Number</label>
                                    <input type="text" id="itemNo" name="itemNo" onChange={text => this.handleChange("item_no", text)} required /><br />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="ItemName">Item Name</label>
                                    <input type="text" id="ItemName" name="ItemName" onChange={text => this.handleChange("item_name", text)} required />
                                </div>
                            </div>
                            <div className='btn-container'>
                                <div>
                                </div>
                                <div className='btns'>
                                    <ClearBtn text={'CANCEL'} click={this.closeModal} />
                                    <SolidBtn text={'ADD SKU'} click={this.handleAdd} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    fetchAllSku: offset => dispatch(fetchAllSku(offset)),
    addSku: data => dispatch(addSku(data)),
    deleteSku: (no, name) => dispatch(deleteSku(no, name)),
    searchSku: (offset, data) => dispatch(searchSku(offset, data))
});

const mapStateToProps = state => ({
    loading: state.config.loading,
    data: state.config.sku,
    newSku: state.config.newSku,
    deletedSku: state.config.deletedSku,
    fulfilled: state.config.fulfilled
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SkuSettings);
