import axios from "axios";

import {
  FETCH_ALL_FLEET_ACTION_TYPES,
  FETCH_ALL_LOCATION_ACTION_TYPES,
  FETCH_ALL_THRESHOLD_ACTION_TYPES,
  FETCH_ALL_SKU_ACTION_TYPES,
  EDIT_THRESHOLD_ACTION_TYPES,
  SEARCH_THRESHOLD_ACTION_TYPES,
  SEARCH_FLEET_ACTION_TYPES,
  SEARCH_SKU_ACTION_TYPES,
  ADD_SKU_ACTION_TYPES,
  DELETE_SKU_ACTION_TYPES,
  ADD_FLEET_ACTION_TYPES,
  EDIT_FLEET_ACTION_TYPES,
  ACTIVATE_FLEET_ACTION_TYPES,
  DELETE_FLEET_ACTION_TYPES,
  SEARCH_LOCATION_ACTION_TYPES,
  ADD_LOCATION_ACTION_TYPES,
  EDIT_LOCATION_ACTION_TYPES,
  DELETE_LOCATION_ACTION_TYPES,
} from "./actionTypes";

const {
  FETCH_ALL_FLEET_REQUEST,
  FETCH_ALL_FLEET_FULFILLED,
  FETCH_ALL_FLEET_REJECTED,
} = FETCH_ALL_FLEET_ACTION_TYPES;

const {
  FETCH_ALL_LOCATION_REQUEST,
  FETCH_ALL_LOCATION_FULFILLED,
  FETCH_ALL_LOCATION_REJECTED,
} = FETCH_ALL_LOCATION_ACTION_TYPES;

const {
  FETCH_ALL_THRESHOLD_REQUEST,
  FETCH_ALL_THRESHOLD_FULFILLED,
  FETCH_ALL_THRESHOLD_REJECTED,
} = FETCH_ALL_THRESHOLD_ACTION_TYPES;

const {
  FETCH_ALL_SKU_REQUEST,
  FETCH_ALL_SKU_FULFILLED,
  FETCH_ALL_SKU_REJECTED,
} = FETCH_ALL_SKU_ACTION_TYPES;

const {
  EDIT_THRESHOLD_REQUEST,
  EDIT_THRESHOLD_FULFILLED,
  EDIT_THRESHOLD_REJECTED,
} = EDIT_THRESHOLD_ACTION_TYPES;

const { SEARCH_FLEET_REQUEST, SEARCH_FLEET_FULFILLED, SEARCH_FLEET_REJECTED } =
  SEARCH_FLEET_ACTION_TYPES;

const { SEARCH_SKU_REQUEST, SEARCH_SKU_FULFILLED, SEARCH_SKU_REJECTED } =
  SEARCH_SKU_ACTION_TYPES;

const {
  SEARCH_THRESHOLD_REQUEST,
  SEARCH_THRESHOLD_FULFILLED,
  SEARCH_THRESHOLD_REJECTED,
} = SEARCH_THRESHOLD_ACTION_TYPES;

const { ADD_SKU_REQUEST, ADD_SKU_FULFILLED, ADD_SKU_REJECTED } =
  ADD_SKU_ACTION_TYPES;

const { DELETE_SKU_REQUEST, DELETE_SKU_FULFILLED, DELETE_SKU_REJECTED } =
  DELETE_SKU_ACTION_TYPES;

const { ADD_FLEET_REQUEST, ADD_FLEET_FULFILLED, ADD_FLEET_REJECTED } =
  ADD_FLEET_ACTION_TYPES;

const { EDIT_FLEET_REQUEST, EDIT_FLEET_FULFILLED, EDIT_FLEET_REJECTED } =
  EDIT_FLEET_ACTION_TYPES;

const { DELETE_FLEET_REQUEST, DELETE_FLEET_FULFILLED, DELETE_FLEET_REJECTED } =
  DELETE_FLEET_ACTION_TYPES;

const {
  ACTIVATE_FLEET_REQUEST,
  ACTIVATE_FLEET_FULFILLED,
  ACTIVATE_FLEET_REJECTED,
} = ACTIVATE_FLEET_ACTION_TYPES;

const { ADD_LOCATION_REQUEST, ADD_LOCATION_FULFILLED, ADD_LOCATION_REJECTED } =
  ADD_LOCATION_ACTION_TYPES;

const {
  SEARCH_LOCATION_REQUEST,
  SEARCH_LOCATION_FULFILLED,
  SEARCH_LOCATION_REJECTED,
} = SEARCH_LOCATION_ACTION_TYPES;

const {
  EDIT_LOCATION_REQUEST,
  EDIT_LOCATION_FULFILLED,
  EDIT_LOCATION_REJECTED,
} = EDIT_LOCATION_ACTION_TYPES;

const {
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_FULFILLED,
  DELETE_LOCATION_REJECTED,
} = DELETE_LOCATION_ACTION_TYPES;

const BASE_URL = "http://18.134.205.75:3008";

// THUNKS
const fetchAllFleet = (offset) => {
  return async (dispatch) => {
    dispatch(fetchAllFleetRequest());
    try {
      const response = await axios.get(
        `${BASE_URL}/fleet/all?limit=10&offset=${offset}`
      );
      console.log(response.data);
      return dispatch(fetchAllFleetFulfilled(response.data));
    } catch (e) {
      console.log(e);
      dispatch(fetchAllFleetRejected());
    }
  };
};

const fetchAllLocation = (offset) => {
  return async (dispatch) => {
    dispatch(fetchAllLocationRequest());
    try {
      const response = await axios.get(
        `${BASE_URL}/location/all/active?limit=10&offset=${offset}`
      );
      console.log(response.data);
      return dispatch(fetchAllLocationFulfilled(response.data));
    } catch (e) {
      console.log(e);
      dispatch(fetchAllLocationRejected(e));
    }
  };
};

const fetchAllThreshold = (offset) => {
  return async (dispatch) => {
    dispatch(fetchAllThresholdRequest());
    try {
      const response = await axios.get(
        `${BASE_URL}/duration/threshold?limit=10&offset=${offset}`
      );
      console.log(response.data);
      return dispatch(fetchAllThresholdFulfilled(response.data));
    } catch (e) {
      console.log(e);
      dispatch(fetchAllThresholdRejected());
    }
  };
};

const fetchAllSku = (offset) => {
  return async (dispatch) => {
    dispatch(fetchAllSkuRequest());
    try {
      const response = await axios.get(
        `${BASE_URL}/all/items?limit=10&offset=${offset}`
      );
      console.log(response.data);
      return dispatch(fetchAllSkuFulfilled(response.data));
    } catch (e) {
      console.log(e);
      dispatch(fetchAllSkuRejected());
    }
  };
};

const editThreshold = (data) => {
  return async (dispatch, getState) => {
    dispatch(editThresholdRequest());
    try {
      const response = await axios.patch(`${BASE_URL}/threshold`, data);
      console.log(response);
      return dispatch(editThresholdFulfilled());
    } catch (e) {
      console.log(e);
      dispatch(editThresholdRejected());
    }
  };
};

const searchFleet = (offset, data) => {
  return async (dispatch) => {
    dispatch(searchFleetRequest());
    try {
      const response = await axios.get(
        `${BASE_URL}/fleet/search/${data}?limit=10&offset=${offset}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return dispatch(searchFleetFulfilled(response.data));
    } catch (e) {
      console.log(e);
      dispatch(searchFleetRejected());
    }
  };
};

const searchSku = (offset, data) => {
  return async (dispatch) => {
    dispatch(searchSkuRequest());
    try {
      const response = await axios.get(
        `${BASE_URL}/items/search/${data}?limit=10&offset=${offset}&company=GBNL`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return dispatch(searchSkuFulfilled(response.data));
    } catch (e) {
      console.log(e);
      dispatch(searchSkuRejected());
    }
  };
};

const searchThreshold = (offset, data) => {
  return async (dispatch) => {
    dispatch(searchThresholdRequest());
    try {
      const response = await axios.get(
        `${BASE_URL}/duration/search?limit=10&offset=${offset}`,
        {
          data: {
            item: "ab",
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
      return dispatch(searchThresholdFulfilled(response.data));
    } catch (e) {
      console.log(e);
      dispatch(searchThresholdRejected());
    }
  };
};

const searchLocation = (offset, data) => {
  return async (dispatch) => {
    dispatch(searchLocationRequest());
    try {
      const response = await axios.get(
        `${BASE_URL}/location/search/${data}?limit=10&offset=${offset}&company=GBNL`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return dispatch(searchLocationFulfilled(response.data));
    } catch (e) {
      console.log(e);
      dispatch(searchLocationRejected());
    }
  };
};

const addSku = (data) => {
  return async (dispatch, getState) => {
    dispatch(addSkuRequest());
    try {
      const response = await axios.post(`${BASE_URL}/items`, data);
      console.log(response);
      return dispatch(addSkuFulfilled());
    } catch (e) {
      console.log(e);
      dispatch(addSkuRejected());
    }
  };
};

const deleteSku = (no, name) => {
  return async (dispatch, getState) => {
    dispatch(deleteSkuRequest());
    try {
      const response = await axios.delete(
        `${BASE_URL}/delete/items?item_no=${no}&item_name=${name}`
      );
      console.log(response);
      return dispatch(deleteSkuFulfilled());
    } catch (e) {
      console.log(e);
      dispatch(deleteSkuRejected());
    }
  };
};

const addFleet = (data) => {
  return async (dispatch, getState) => {
    dispatch(addFleetRequest());
    try {
      const response = await axios.post(`${BASE_URL}/fleet`, data);
      console.log(response);
      return dispatch(addFleetFulfilled());
    } catch (e) {
      console.log(e);
      dispatch(addFleetRejected());
    }
  };
};

const editFleet = (data, vehiclePlateNumber) => {
  return async (dispatch, getState) => {
    dispatch(editFleetRequest());
    try {
      const response = await axios.put(
        `${BASE_URL}/update/fleet/${vehiclePlateNumber}`,
        data
      );
      console.log(response);
      return dispatch(editFleetFulfilled());
    } catch (e) {
      console.log(e);
      dispatch(editFleetRejected());
    }
  };
};

const activateFleet = (vehiclePlateNumber) => {
  return async (dispatch, getState) => {
    dispatch(activateFleetRequest());
    try {
      const response = await axios.patch(
        `${BASE_URL}/activate/fleet/${vehiclePlateNumber}`
      );
      return dispatch(activateFleetFulfilled());
    } catch (e) {
      console.log(e);
      dispatch(activateFleetRejected());
    }
  };
};

const deleteFleet = (vehiclePlateNumber) => {
  console.log(vehiclePlateNumber);
  return async (dispatch, getState) => {
    dispatch(deleteFleetRequest());
    try {
      const response = await axios.delete(
        `${BASE_URL}/delete/fleet/${vehiclePlateNumber}`
      );
      console.log(response);
      return dispatch(deleteFleetFulfilled());
    } catch (e) {
      console.log(e);
      dispatch(deleteFleetRejected());
    }
  };
};

const addLocation = (data) => {
  return async (dispatch, getState) => {
    dispatch(addLocationRequest());
    try {
      const response = await axios.post(`${BASE_URL}/location`, data);
      console.log(response);
      return dispatch(addLocationFulfilled());
    } catch (e) {
      console.log(e);
      dispatch(addLocationRejected());
    }
  };
};

const editLocation = (data) => {
  return async (dispatch, getState) => {
    dispatch(editLocationRequest());
    try {
      const response = await axios.put(`${BASE_URL}/location/update`, data);
      console.log(response);
      return dispatch(editLocationFulfilled());
    } catch (e) {
      console.log(e);
      dispatch(editLocationRejected());
    }
  };
};

const deleteLocation = (depot) => {
  console.log(`${BASE_URL}/location/delete?depot=${depot}`);
  return async (dispatch, getState) => {
    dispatch(deleteLocationRequest());
    try {
      const response = await axios.delete(
        `${BASE_URL}/location/delete?depot=${depot}`
      );
      console.log(response);
      return dispatch(deleteLocationFulfilled());
    } catch (e) {
      console.log(e);
      dispatch(deleteLocationRejected());
    }
  };
};

// ACTION CREATORS

const fetchAllFleetRequest = () => ({
  type: FETCH_ALL_FLEET_REQUEST,
});

const fetchAllFleetFulfilled = (data) => ({
  type: FETCH_ALL_FLEET_FULFILLED,
  payload: data,
});

const fetchAllFleetRejected = () => ({
  type: FETCH_ALL_FLEET_REJECTED,
});

const fetchAllLocationRequest = () => ({
  type: FETCH_ALL_LOCATION_REQUEST,
});

const fetchAllLocationFulfilled = (data) => ({
  type: FETCH_ALL_LOCATION_FULFILLED,
  payload: data,
});

const fetchAllLocationRejected = () => ({
  type: FETCH_ALL_LOCATION_REJECTED,
});

const fetchAllThresholdRequest = () => ({
  type: FETCH_ALL_THRESHOLD_REQUEST,
});

const fetchAllThresholdFulfilled = (data) => ({
  type: FETCH_ALL_THRESHOLD_FULFILLED,
  payload: data,
});

const fetchAllThresholdRejected = () => ({
  type: FETCH_ALL_THRESHOLD_REJECTED,
});

const fetchAllSkuRequest = () => ({
  type: FETCH_ALL_SKU_REQUEST,
});

const fetchAllSkuFulfilled = (data) => ({
  type: FETCH_ALL_SKU_FULFILLED,
  payload: data,
});

const fetchAllSkuRejected = () => ({
  type: FETCH_ALL_SKU_REJECTED,
});

const editThresholdRequest = () => ({
  type: EDIT_THRESHOLD_REQUEST,
});

const editThresholdFulfilled = () => ({
  type: EDIT_THRESHOLD_FULFILLED,
});

const editThresholdRejected = () => ({
  type: EDIT_THRESHOLD_REJECTED,
});

const searchThresholdRequest = () => ({
  type: SEARCH_THRESHOLD_REQUEST,
});

const searchThresholdFulfilled = (data) => ({
  type: SEARCH_THRESHOLD_FULFILLED,
  payload: data,
});

const searchThresholdRejected = () => ({
  type: SEARCH_THRESHOLD_REJECTED,
});

const addSkuRequest = () => ({
  type: ADD_SKU_REQUEST,
});

const addSkuFulfilled = () => ({
  type: ADD_SKU_FULFILLED,
});

const addSkuRejected = () => ({
  type: ADD_SKU_REJECTED,
});

const searchSkuRequest = () => ({
  type: ADD_SKU_REQUEST,
});

const searchSkuFulfilled = () => ({
  type: SEARCH_SKU_FULFILLED,
});

const searchSkuRejected = () => ({
  type: SEARCH_SKU_REJECTED,
});

const deleteSkuRequest = () => ({
  type: DELETE_SKU_REQUEST,
});

const deleteSkuFulfilled = () => ({
  type: DELETE_SKU_FULFILLED,
});

const deleteSkuRejected = () => ({
  type: DELETE_SKU_REJECTED,
});

const addFleetRequest = () => ({
  type: ADD_FLEET_REQUEST,
});

const addFleetFulfilled = () => ({
  type: ADD_FLEET_FULFILLED,
});

const addFleetRejected = () => ({
  type: ADD_FLEET_REJECTED,
});

const searchFleetRequest = () => ({
  type: SEARCH_FLEET_REQUEST,
});

const searchFleetFulfilled = () => ({
  type: SEARCH_FLEET_FULFILLED,
});

const searchFleetRejected = () => ({
  type: SEARCH_FLEET_REJECTED,
});

const editFleetRequest = () => ({
  type: EDIT_FLEET_REQUEST,
});

const editFleetFulfilled = () => ({
  type: EDIT_FLEET_FULFILLED,
});

const editFleetRejected = () => ({
  type: EDIT_FLEET_REJECTED,
});

const deleteFleetRequest = () => ({
  type: DELETE_FLEET_REQUEST,
});

const deleteFleetFulfilled = () => ({
  type: DELETE_FLEET_FULFILLED,
});

const deleteFleetRejected = () => ({
  type: DELETE_FLEET_REJECTED,
});

const addLocationRequest = () => ({
  type: ADD_LOCATION_REQUEST,
});

const addLocationFulfilled = () => ({
  type: ADD_LOCATION_FULFILLED,
});

const addLocationRejected = () => ({
  type: ADD_LOCATION_REJECTED,
});

const searchLocationRequest = () => ({
  type: SEARCH_LOCATION_REQUEST,
});

const searchLocationFulfilled = () => ({
  type: SEARCH_LOCATION_FULFILLED,
});

const searchLocationRejected = () => ({
  type: SEARCH_LOCATION_REJECTED,
});

const activateFleetRequest = () => ({
  type: ACTIVATE_FLEET_REQUEST,
});

const activateFleetFulfilled = () => ({
  type: ACTIVATE_FLEET_FULFILLED,
});

const activateFleetRejected = () => ({
  type: ACTIVATE_FLEET_REJECTED,
});

const editLocationRequest = () => ({
  type: EDIT_LOCATION_REQUEST,
});

const editLocationFulfilled = () => ({
  type: EDIT_LOCATION_FULFILLED,
});

const editLocationRejected = () => ({
  type: EDIT_LOCATION_REJECTED,
});

const deleteLocationRequest = () => ({
  type: DELETE_LOCATION_REQUEST,
});

const deleteLocationFulfilled = () => ({
  type: DELETE_LOCATION_FULFILLED,
});

const deleteLocationRejected = () => ({
  type: DELETE_LOCATION_REJECTED,
});

export {
  fetchAllFleet,
  fetchAllLocation,
  fetchAllThreshold,
  fetchAllSku,
  editThreshold,
  searchThreshold,
  searchLocation,
  addSku,
  deleteSku,
  activateFleet,
  searchFleet,
  searchSku,
  addFleet,
  editFleet,
  deleteFleet,
  addLocation,
  editLocation,
  deleteLocation,
};
