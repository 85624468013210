import React, {
  Dispatch,
  FocusEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import SolidBtn from "../SolidBtn";
import filterIcon from "../../../assets/filter.svg";
import chevronDown from "../../../assets/chevron-down.svg";
import { toCsv } from "../../../utils/csv";
import { Button } from "./Button";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFilter,
  faSearch,
  faCircle,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(faFilter, faSearch, faCircle, faPlus);

export const TableTab = ({
  navItems,
  data,
  text,
  file,
  total,
  depots,
  byRegion,
  canFilter = true,
  setByDepot,
  setByRegion,
  setBySpecific,
  setByCustomStart,
  setByCustomEnd,
  setWillFilter,
}) => {
  const [csvText, setText] = useState("");
  const [fileName, setFileName] = useState("");
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedDepot, setSelectedDepot] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [inputType, setInputType] = useState({
    allTime: false,
    specific: false,
    custom: false,
  });
  const [value, setValue] = useState("");
  const [regionValue, setRegionValue] = useState("all");
  const [depotValue, setDepotValue] = useState("");
  const [filter, setFilter] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [regionSelect, setRegionSelect] = useState("");
  const [isRegionSelectOpen, setIsRegionSelectOpen] = useState(false);
  const [depotSelect, setDepotSelect] = useState("");
  const [isDepotSelectOpen, setIsDepotSelectOpen] = useState(false);
  const [isPop, setIsPop] = useState(false);
  const popRef = useRef(null);
  const selectRef = useRef(null);
  const filterRef = useRef(null);
  const endDateRef = useRef(null);

  useEffect(() => {
    if (data?.length > 0 && data !== undefined && file !== undefined) {
      setText(toCsv(data));
      setFileName(file);
    }
  }, [data, file]);

  useEffect(() => {
    if (setByRegion) setByRegion(regionValue);
  }, [regionValue]);

  useEffect(() => {
    if (setByDepot) setByDepot(depotValue);
  }, [depotValue]);

  useEffect(() => {
    if (setBySpecific) setBySpecific(value);
  }, [value]);

  useEffect(() => {
    if (setByCustomStart) setByCustomStart(startDate);
    if (setByCustomEnd) setByCustomEnd(endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    let handler = (e) => {
      if (
        !popRef.current?.contains(e.target) &&
        !selectRef.current?.contains(e.target)
      )
        setIsPop(false);
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    let handler = (e) => {
      if (
        !selectRef.current?.contains(e.target) &&
        !isPop &&
        !filterRef.current?.contains(e.target)
      )
        setOpen(false);
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const onOptionClicked = (value) => {
    setSelected(value);
  };

  const onRegionClicked = (value) => {
    setRegionValue(value);
    setFilter(!filter);
    setIsPop(false);
    setOpen(false);
    if (setWillFilter) setWillFilter(String(Math.random()));
  };

  const onDepotClicked = (value) => {
    setDepotSelect(value);
    setDepotValue(value);
    setFilter(!filter);
    setIsPop(false);
    setOpen(false);
    if (setWillFilter) setWillFilter(String(Math.random()));
  };

  const onInputBlur = (e) => {
    const { name } = e.target;
    setInputType({ ...inputType, [name]: false });
  };

  const toggle = (value) => {
    setIsSelectOpen(value);
  };

  const toggleRegion = (value) => {
    setIsRegionSelectOpen(value);
  };

  const toggleDepot = (value) => {
    setIsDepotSelectOpen(value);
  };

  function runCleanup() {
    setValue("");
    setStartDate("");
    setEndDate("");
  }

  return (
    <>
      <Nav>
        <div className="flex tab">
          <div className="top-left">
            <div className="flex">
              <button
                ref={filterRef}
                onClick={() => setOpen(!open)}
                className={`flex btn btn-default-ghost pointer filter-div ${
                  canFilter ? "" : "no-display"
                }`}
              >
                <div>
                  <FontAwesomeIcon icon="filter" className="filter" />
                </div>
                <span className="fw-600 fs-100 filter-text">Filter:</span>
              </button>
            </div>
            {open && (
              <aside ref={selectRef} className="grid">
                <div
                  className="flex filter-options pointer"
                  onClick={() => toggleRegion(!isRegionSelectOpen)}
                >
                  <p>{selectedRegion || "Filter by region"}</p>
                  <span className={isRegionSelectOpen ? "rotate" : ""}>
                    <img src={chevronDown} alt="" />
                  </span>
                </div>
                <div
                  className={` ${
                    isRegionSelectOpen ? "open open-nospace" : ""
                  }`}
                >
                  <div
                    className={`${
                      isRegionSelectOpen ? "grid grid-nospace" : "no-display"
                    }`}
                  >
                    <select
                      className="filter-select"
                      onChange={(e) => onRegionClicked(e.target.value)}
                    >
                      <option value="Region" disabled selected hidden>
                        Region
                      </option>
                      <option value="all">ALL</option>
                      <option value="lg">LG</option>
                      <option value="mb">MB</option>
                      <option value="sw">SW</option>
                      <option value="se">SE</option>
                      <option value="nt">NT</option>
                    </select>
                  </div>
                </div>

                <div
                  className="flex filter-options pointer"
                  onClick={() => toggleDepot(!isDepotSelectOpen)}
                >
                  <p>{selectedDepot || "Filter by depot"}</p>
                  <span className={isDepotSelectOpen ? "rotate" : ""}>
                    <img src={chevronDown} alt="" />
                  </span>
                </div>
                <div
                  className={` ${isDepotSelectOpen ? "open open-nospace" : ""}`}
                >
                  <div
                    className={`${
                      isDepotSelectOpen ? "grid grid-nospace" : "no-display"
                    }`}
                  >
                    <select
                      className="filter-select"
                      onChange={(e) => onDepotClicked(e.target.value)}
                    >
                      <option value="Depot" disabled selected hidden>
                        Depot
                      </option>
                      <option value="all">ALL</option>
                      {depots.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div
                  className="flex filter-options pointer"
                  onClick={() => toggle(!isSelectOpen)}
                >
                  <p>{selected || "Filter by date"}</p>
                  <span className={isSelectOpen ? "rotate" : ""}>
                    <img src={chevronDown} alt="" />
                  </span>
                </div>

                <div className={`select ${isSelectOpen ? "open" : ""}`}>
                  <ul className={`${isSelectOpen ? "grid" : "no-display"}`}>
                    <input
                      className="pointer"
                      onClick={() => {
                        setValue(new Date().toISOString().split("T")[0]);
                        onOptionClicked("Today");
                      }}
                      type="text"
                      placeholder="Today"
                      name="today"
                      readOnly
                    />
                    <input
                      className="pointer"
                      onFocus={() =>
                        setInputType({ ...inputType, specific: true })
                      }
                      onBlur={(e) => onInputBlur(e)}
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                      onClick={() => {
                        onOptionClicked("Specific date");
                      }}
                      type={inputType.specific ? "date" : "text"}
                      value={inputType.specific ? value : "Specific date"}
                      name="specific"
                    />
                    <input
                      className="pointer"
                      onClick={() => {
                        onOptionClicked("Custom date");
                        setIsPop((isPop) => !isPop);
                      }}
                      type="text"
                      value="Custom date"
                      name="custom"
                      readOnly
                    />
                  </ul>
                </div>
                <Button
                  className={`${value ? "" : "no-display"}`}
                  onClick={() => {
                    setFilter(!filter);
                    setIsPop(false);
                    setOpen(false);
                    if (setWillFilter) setWillFilter(String(Math.random()));
                  }}
                >
                  Filter
                </Button>
              </aside>
            )}
            {isPop && (
              <div ref={popRef} className="pop-up">
                <div className="grid">
                  <p>Custom date</p>
                  <div className="flex">
                    <div className="grid">
                      <label htmlFor="startDate">From:</label>
                      <input
                        type="date"
                        id="startDate"
                        max={new Date().toISOString().split("T")[0]}
                        onChange={(e) => {
                          setStartDate("");
                          setStartDate(e.target.value);
                          endDateRef.current?.focus();
                        }}
                      />
                    </div>

                    <div className="grid">
                      <label htmlFor="endDate">To:</label>
                      <input
                        ref={endDateRef}
                        type="date"
                        id="endDate"
                        max={new Date().toISOString().split("T")[0]}
                        onChange={(e) => {
                          setEndDate("");
                          setValue("");
                          setEndDate(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <Button
                    className="custom"
                    onClick={() => {
                      setFilter(!filter);
                      setIsPop(false);
                      setOpen(false);
                      if (setWillFilter) setWillFilter(String(Math.random()));
                    }}
                  >
                    Filter
                  </Button>
                </div>
              </div>
            )}
            {byRegion !== undefined && (
              <p className="nav-text key">
                Region: <span className="value">{byRegion.toUpperCase()}</span>
              </p>
            )}
            {total !== undefined && (
              <p className="nav-text key">
                Total Number of Orders: <span className="value">{total}</span>
              </p>
            )}
          </div>
          <div className="top-right">
            <a
              className="pointer"
              download={fileName}
              href={`data:text/csv;charset=utf-8,${encodeURIComponent(
                csvText
              )}`}
            >
              <SolidBtn text="EXPORT CSV" />
            </a>
          </div>
        </div>
      </Nav>
      {/* <Outlet
        context={[
          toCsv,
          setText,
          setFileName,
          value,
          startDate,
          endDate,
          filter,
        ]}
      /> */}
    </>
  );
};

const Nav = styled.nav`
  position: relative;
  margin-top: 80px;

  .rotate {
    transform: translateY(-50%) rotate(0.5turn);
  }
  .tab {
    padding: 1em 0;
    align-items: center;
    justify-content: space-between;
    background-color: hsla(var(--clr-light));
  }
  .tab-nav {
    --gap: 4rem;
    align-items: center;
  }
  .tab-nav .active {
    color: hsla(var(--clr-blue-tint-100));
  }
  .tab-nav .active::after {
    position: absolute;
    content: "";
    width: 100%;
    border-bottom: 2px solid hsla(var(--clr-blue-tint-100));
    padding-bottom: 4em;
  }
  .tab-nav li {
    transition: all 0.3s ease-in-out;
  }

  .tab-nav a {
    color: hsla(var(--clr-black-tint-200));
    position: relative;
    display: grid;
    align-items: center;
  }
  .tab-nav > * > a:hover::after {
    position: absolute;
    content: "";
    color: hsla(var(--clr-blue-tint-100));
    width: 100%;
    border-bottom: 2px solid hsla(var(--clr-blue-tint-100));
    padding-bottom: 4em;
  }
  .nav-text {
    color: #3c3872;
    font-size: 18px;
  }
  button {
    --gap: 0.3rem;
    align-items: center;
    justify-content: space-between;
    padding: 0.3em 1em;
  }
  .export-btn {
    background-color: #262262;
    color: #fff;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
  aside {
    background-color: hsla(var(--clr-light));
    position: absolute;
    right: 5%;
    width: max-content;
    height: max-content;
    place-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    padding: 3em 1.5em;
    z-index: 9;
    transition-duration: 500ms;

    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px;
    left: 0px;
    z-index: 20;
    padding: 30px 16px;
    background: #ffffff;
    box-shadow: 0px 3px 14px rgba(63, 63, 68, 0.15);
    gap: 0 !important;
  }
  .filter-options {
    align-items: center;
    justify-content: space-between;
    border: 1px solid hsla(var(--clr-border));
    border-radius: 2px;
    width: 12em;
    padding: 0.625em;

    span {
      transition: transform 0.4s;
    }
  }
  .select {
    width: 12em;
    background: hsla(var(--clr-light));
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    max-height: 0;
    opacity: 0;

    transition-duration: 500ms;

    input {
      outline: transparent solid 2px;
      border: none;
      width: 100%;
      padding: 0.7em;
    }
    input::placeholder {
      color: hsla(var(--clr-dark));
      opacity: 1;
    }
    input:hover {
      background: hsla(var(--clr-yellow-tint-900));
    }
  }

  .open {
    padding-block: 1em;
    max-height: 100%;
    opacity: 1;
  }
  .open-nospace {
    padding-block: 0em !important;
  }
  .grid-nospace {
    gap: 0;
  }

  .select ul {
    --gap: 0;
  }

  .select li {
    padding: 0.7em;
  }

  .select li:hover {
    background: hsla(var(--clr-yellow-tint-900));
  }

  .pop-up {
    width: max-content;
    height: 30vh;
    margin: auto;
    padding: 0.5em 1em;
    position: absolute;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    inset: 0;
    background: #fff;
    z-index: 99;
    display: grid;

    input {
      padding: 0.625em;
      border: 1px solid hsla(var(--clr-border));
      border-radius: 2px;
      width: 12em;
    }
    .custom {
      justify-self: end;
      height: 40px;
    }
  }
  .filter-div {
    border: 1px solid #2a1770;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 34px;
    width: 90px;
    padding: 7px;
    box-sizing: border-box;
    margin-right: 40px;
    cursor: pointer;
  }
  .filter-div .filter {
    margin-right: 5px;
    font-size: 18px;
    color: #2a1770;
  }
  .filter-div .filter-text {
    color: #2a1770;
    font-weight: bold;
    font-size: 16px;
  }
  .filter-div:hover {
    background: #2a1770;
  }
  .filter-div:hover .filter {
    color: #ffffff;
  }
  .filter-div:hover .filter-text {
    color: #ffffff;
  }
  .top-left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .key {
    font-size: 16px;
    color: #707070;
    margin-right: 40px;
  }
  .value {
    font-weight: bold;
    color: #2a1770;
    margin-left: 5px;
  }
  .jLQKkL .grid {
    gap: 0 !important;
  }

  .filter-select {
    border: 1px solid #707070;
    border-radius: 6px;
    width: 190px;
    height: 50px;
    color: #707070;
    font-size: 16px;
    margin-bottom: 30px;
    padding-left: 8px;
    box-sizing: border-box;
    background: #ffffff;
  }
`;
