import React, { Component } from "react";
import Header from "../header/Header";
import SolidBtn from "../common/SolidBtn";
import ClearBtn from "../common/ClearBtn";
import Loader from "../common/Loader";
import moment from "moment";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import {
  fetchReport,
  filterReport,
  searchReport,
} from "../../actions/dashboard";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFilter,
  faSearch,
  faCircle,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(faFilter, faSearch, faCircle, faPlus);

class Report extends Component {
  container = React.createRef();
  state = {
    open: false,
    report: [],
    page: 1,
    regionDisplayed: "ALL",
    region: "",
    date: "",
    dateDisplayed: "",
  };

  async componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    await this.props.fetchReport(1);
    if (this.props.report) {
      this.setState({
        report: this.props.report.result,
        count: this.props.report.count,
        regionDisplayed: "ALL",
        date: "",
        dateDisplayed: "",
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.container.current &&
      !this.container.current.contains(event.target)
    ) {
      this.setState({
        open: false,
      });
    }
  };

  more = async () => {
    await this.setState({
      page: this.state.page + 1,
    });
    if (this.state.regionDisplayed === "ALL") {
      await this.props.fetchReport(this.state.page);
    } else {
      await this.props.filterReport(
        this.state.regionDisplayed,
        this.state.date,
        this.state.page
      );
    }
    if (this.props.report) {
      this.setState({
        report: this.state.report.concat(this.props.report.result),
        count: this.state.count + this.props.report.count,
      });
    }
  };

  handleChange = (name, e) => {
    this.setState({
      [name]: e.target.value,
    });
  };

  handleDate = (date) => {
    this.setState({
      dateDisplayed: date,
      date: moment(date).format("YYYY-MM-DD"),
    });
  };

  handleSearch = async (item) => {
    // console.log(item.target.value)
    await this.props.searchReport(1, { item: item.target.value });
    if (this.props.report) {
      this.setState({
        report: this.props.report.result,
        count: this.props.report.count,
        regionDisplayed: "ALL",
      });
    }
  };

  handleFilter = async () => {
    await this.setState({
      page: 1,
      report: [],
      open: false,
    });
    console.log(this.state.region, this.state.date, this.state.page);
    if (this.state.region === "all") {
      return this.componentDidMount();
    }
    await this.props.filterReport(
      this.state.region,
      this.state.date,
      this.state.page
    );
    if (this.props.report) {
      this.setState({
        report: this.props.report.result,
        regionDisplayed: this.state.region,
        count: this.props.report.count,
        date: "",
        dateDisplayed: "",
      });
    }
    console.log(this.state.date);
  };

  filterClick = async () => {
    await this.setState({
      open: !this.state.open,
    });
    if (this.state.open === false) {
      this.setState({
        date: "",
        dateDisplayed: "",
      });
    }
  };

  filterClose = () => {
    this.setState({
      open: false,
      date: "",
      dateDisplayed: "",
    });
  };

  render() {
    const { open, report, count, regionDisplayed } = this.state;
    return (
      <div id="home">
        <Header />
        <div className="home">
          <div className="top" ref={this.container}>
            <div className="top-left">
              <div className="filter-div" onClick={this.filterClick}>
                <FontAwesomeIcon icon="filter" className="filter" />
                <p className="filter-text">Filter:</p>
              </div>
              <p className="key">
                Regions:{" "}
                <span className="value">{regionDisplayed.toUpperCase()}</span>
              </p>
              <p className="key">
                Showing: <span className="value">{count}</span>
              </p>
            </div>
            <div className="top-right">
              {/* <div className='search-container'>
                                <FontAwesomeIcon icon="search" className='search-icon' />
                                <input type="text" id="search" name="search" placeholder="Search Orders" className='search' onChange={text => this.handleSearch(text)} />
                            </div> */}
              <CSVLink data={report} style={{ textDecoration: "none" }}>
                <SolidBtn text="EXPORT CSV" />
              </CSVLink>
            </div>
            {open && (
              <div className="filter-drop">
                <select
                  className="filter-select"
                  onChange={(text) => this.handleChange("region", text)}
                >
                  <option value="">Region</option>
                  <option value="all">ALL</option>
                  <option value="lg">LG</option>
                  <option value="mb">MB</option>
                  <option value="sw">SW</option>
                  <option value="se">SE</option>
                  <option value="nt">NT</option>
                </select>
                <DatePicker
                  className="filter-select"
                  placeholderText="Date"
                  dateFormat="yyyy-MM-dd"
                  onChange={this.handleDate}
                  selected={this.state.dateDisplayed}
                />
                <div className="filter-btns">
                  <ClearBtn text="CLEAR" click={this.filterClose} />
                  <SolidBtn text="APPLY" click={this.handleFilter} />
                </div>
              </div>
            )}
          </div>
          {/* <div style="overflow-x:auto;"> */}
          <div className="table-container">
            <table className="table-header">
              <thead>
                <tr>
                  <th>Transaction ID</th>
                  <th>Pick Up Location</th>
                  <th>Item Name</th>
                  <th>Qty</th>
                  <th>Vehicle Reg No.</th>
                  <th>Vehicle Capacity (Cases)</th>
                  <th>
                    Time
                    <br />
                    Date
                  </th>
                  <th>Drop off Location</th>
                  <th>Status</th>
                </tr>
              </thead>
            </table>
            {report.length > 0 && (
              <table className="table-body">
                <tbody>
                  {report.map((item, i) => (
                    <tr key={i}>
                      <td className="tx-id">{item.id}</td>
                      <td>
                        {Array.isArray(item.location_data)
                          ? item.location_data.map((item, index) => (
                              <p key={index} className="multi">
                                {item.depot}
                              </p>
                            ))
                          : [item.location_data].map((item, index) => (
                              <p key={index} className="multi">
                                {item.depot}
                              </p>
                            ))}
                      </td>
                      <td>{item.item_name}</td>
                      <td>{item.stock}</td>
                      <td>{item.vehicle_plate_no}</td>
                      <td>{item.vehicle_data.capacity}</td>
                      <td>
                        {moment(item.updatedAt).format("h:mm a")}
                        <br />
                        <span className="date">
                          {moment(item.updatedAt).format("MMMM Do YYYY")}
                        </span>
                      </td>
                      <td>{item.depot}</td>
                      <td className={item.approval_state ? "status" : null}>
                        {item.approval_state ? "Approved" : "Not approved"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {this.props.loading && <Loader />}
          </div>
          {!this.props.loading && report.length > 1 && (
            <div className="more">
              <ClearBtn text="LOAD MORE" click={this.more} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchReport: (page) => dispatch(fetchReport(page)),
  filterReport: (region, date, page) =>
    dispatch(filterReport(region, date, page)),
  searchReport: (page, data) => dispatch(searchReport(page, data)),
});

const mapStateToProps = (state) => ({
  loading: state.dash.loading,
  report: state.dash.report,
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);
