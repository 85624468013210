import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import Header from "../header/Header";
import SolidBtn from "../common/SolidBtn";
import ClearBtn from "../common/ClearBtn";
import ClearBtnDisabled from "../common/ClearBtnDisabled";
import Modal from "../common/Modal";
import ConfirmModal from "../common/ConfirmModal";
import Loader from "../common/Loader";
import Toast from "../common/Toast";
import pickup from "../../assets/pickup.svg";
import dropoff from "../../assets/dropoff.svg";
import warehouse from "../../assets/warehouse.jpg";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import {
  fetchAllOrders,
  approveOrder,
  fetchMultiple,
} from "../../actions/dashboard";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFilter,
  faSearch,
  faCircle,
  faPlus,
  faMinus,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(faFilter, faSearch, faCircle, faPlus, faMinus, faCheck);

class Dashboard extends Component {
  container = React.createRef();
  constructor() {
    super();

    this.state = {
      expandedRows: null,
      show: false,
      open: false,
      confirm: false,
      dash: [],
      toast: false,
      region: "lg",
      regionDisplayed: "lg",
      currentPage: 1,
      resultPerPage: 10,
      next: null,
      previous: null,
    };
  }

  async componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    await this.props.fetchAllOrders(this.state.region, 1);
    console.log(this.props.data);
    if (this.props.data) {
      return this.setState({
        dash: this.props.data.result,
        count: this.props.data.count,
        currentPage: 1,
        next: this.props.data.next,
        previous: this.props.data.previous,
      });
    } else if (this.props.errorMsg) {
      console.log(this.props.errorMsg);
      this.logout();
    } else {
      return;
    }
  }

  logout = () => {
    localStorage.clear();
    this.props.history.push({
      pathname: "/login",
    });
  };

  loadMultiple = async (plate) => {
    await this.props.fetchMultiple(plate);
  };

  reload = () => {
    this.componentDidMount();
  };

  toastToggle = () => {
    this.setState({
      toast: true,
    });
    setTimeout(() => {
      this.setState({
        toast: false,
      });
    }, 5000);
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.container.current &&
      !this.container.current.contains(event.target)
    ) {
      this.setState({
        open: false,
      });
    }
  };

  handleApprove = async () => {
    this.setState({
      // approvedId: id,
      show: false,
      confirm: false,
    });
    if (this.state.expandedRow !== null) {
      this.setState({
        expandedRow: null,
      });
    }
    console.log(this.state.approvedId, this.state.region.toUpperCase());
    await this.props.approveOrder(
      this.state.approvedId,
      this.state.region.toUpperCase()
    );

    if (this.props.approved) {
      this.toastToggle();
      return this.componentDidMount();
    }
  };

  filter = () => {
    this.componentDidMount();
    this.setState({
      open: false,
      regionDisplayed: this.state.region,
    });
  };

  handleFilter = (event) => {
    this.setState({
      region: event.target.value,
    });
  };

  openModal = async (plate, id) => {
    await this.loadMultiple(plate);
    // let multipleData = this.props.multiple.filter((entry) => {
    //     return entry.dropoff_location;
    // });
    var newData = [];

    for (var i = 0; i < this.props.multiple.length; i++) {
      newData.push(
        this.props.multiple[i].pickup_location.depot,
        this.props.multiple[i].dropoff_location
      );
    }
    console.log(newData);
    this.setState({
      show: true,
      id: id,
    });
  };

  closeModal = () => {
    this.setState({
      show: false,
    });
  };

  openConfirm = (e, id) => {
    this.setState({
      approvedId: id,
      confirm: true,
    });
  };

  closeConfirm = () => {
    this.setState({
      confirm: false,
    });
  };

  handleRowClick(rowId) {
    const currentExpandedRow = this.state.expandedRow;
    this.setState({ expandedRow: rowId === currentExpandedRow ? null : rowId });
  }

  filterClick = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  filterClose = () => {
    this.setState({
      open: false,
    });
  };

  handlePagination = async (event) => {
    console.log(Number(event.target.id));
    this.setState({
      currentPage: Number(event.target.id),
    });
    await this.props.fetchAllOrders(this.state.region, Number(event.target.id));
    console.log(this.props.data);
    if (this.props.data) {
      return this.setState({
        dash: this.props.data.result,
        count: this.props.data.count,
        next: this.props.data.next,
        previous: this.props.data.previous,
      });
    } else if (this.props.errorMsg.status === 401) {
      this.logout();
    }
  };

  nextPagination = async () => {
    this.setState({
      currentPage: this.state.currentPage + 1,
    });
    await this.props.fetchAllOrders(
      this.state.region,
      this.state.currentPage + 1
    );
    console.log(this.props.data);
    if (this.props.data) {
      return this.setState({
        dash: this.props.data.result,
        count: this.props.data.count,
        next: this.props.data.next,
        previous: this.props.data.previous,
      });
    } else if (this.props.errorMsg.status === 401) {
      this.logout();
    }
  };

  prevPagination = async () => {
    this.setState({
      currentPage: this.state.currentPage - 1,
    });
    await this.props.fetchAllOrders(
      this.state.region,
      this.state.currentPage - 1
    );
    console.log(this.props.data);
    if (this.props.data) {
      return this.setState({
        dash: this.props.data.result,
        count: this.props.data.count,
        next: this.props.data.next,
        previous: this.props.data.previous,
      });
    } else if (this.props.errorMsg.status === 401) {
      this.logout();
    }
  };

  renderItem(item) {
    const clickCallback = async (e, plate) => {
      await this.loadMultiple(plate);
      this.handleRowClick(item.id);
    };
    // className={item.multiple_dropoff ? 'open' : ''}
    const itemRows = [
      <tr
        key={"row-data-" + item.id}
        className={
          item.multiple_dropoff
            ? this.state.expandedRow === item.id
              ? "open"
              : "pointer"
            : null
        }
        onClick={
          item.multiple_dropoff
            ? () => this.openModal(item.vehicle_data.vehicle_name, item.id)
            : null
        }
      >
        <td
          className={
            this.state.expandedRow === item.id ? "tx-id-open" : "tx-id"
          }
          onClick={(e) =>
            clickCallback(e.stopPropagation(), item.vehicle_data.vehicle_name)
          }
        >
          {item.multiple_dropoff ? (
            this.state.expandedRow === item.id ? (
              <FontAwesomeIcon icon="minus" className="plus" />
            ) : (
              <FontAwesomeIcon icon="plus" className="plus" />
            )
          ) : (
            <FontAwesomeIcon icon="minus" className="clear" />
          )}
          {item.id}
        </td>
        <td>
          {Array.isArray(item.location_data)
            ? item.location_data.map((item, index) => (
                <p key={index} className="multi">
                  {item.depot}
                </p>
              ))
            : [item.location_data].map((item, index) => (
                <p key={index} className="multi">
                  {item.depot}
                </p>
              ))}
        </td>
        <td>{item.item_name}</td>
        <td>{item.stock}</td>
        {(Array.isArray(item.location_data)
          ? item.location_data.length > 1
          : [item.location_data].length > 1) &&
          item.multiple_dropoff === true && (
            <td>
              {item.vehicle_data.vehicle_name}
              <div
                className="multiple-badge-dropoff"
                onClick={(e) =>
                  clickCallback(
                    e.stopPropagation(),
                    item.vehicle_data.vehicle_name
                  )
                }
              >
                MULTIPLE PICKUP
                <br />
                MULTIPLE DROPOFF
              </div>
            </td>
          )}
        {(Array.isArray(item.location_data)
          ? item.location_data.length > 1
          : [item.location_data].length > 1) &&
          item.multiple_dropoff === false && (
            <td>
              {item.vehicle_data.vehicle_name}
              <div className="multiple-badge-pickup">MULTIPLE PICKUP</div>
            </td>
          )}
        {(Array.isArray(item.location_data)
          ? item.location_data.length === 1
          : [item.location_data].length === 1) &&
          item.multiple_dropoff === true && (
            <td>
              {item.vehicle_data.vehicle_name}
              <div
                className="multiple-badge-dropoff"
                onClick={(e) =>
                  clickCallback(
                    e.stopPropagation(),
                    item.vehicle_data.vehicle_name
                  )
                }
              >
                MULTIPLE DROPOFF
              </div>
            </td>
          )}
        {(Array.isArray(item.location_data)
          ? item.location_data.length === 1
          : [item.location_data].length === 1) &&
          item.multiple_dropoff === false && (
            <td>{item.vehicle_data.vehicle_name}</td>
          )}
        <td>{item.vehicle_data.capacity}</td>
        <td>{item.depot}</td>
        <td>
          <FontAwesomeIcon
            icon="circle"
            className={item.approval_state === false ? "pending" : "approved"}
          />
          <br />
          {item.approval_state === false ? "Pending" : "Approved"}
        </td>
        <td>
          {item.approval_state === false ? (
            <ClearBtn
              text="APPROVE"
              click={(e) => this.openConfirm(e.stopPropagation(), item.id)}
            />
          ) : (
            <ClearBtnDisabled text="APPROVE" />
          )}
        </td>
      </tr>,
    ];
    // (e) => this.handleApprove(e.stopPropagation(), item.id, (this.state.region).toUpperCase())
    if (this.state.expandedRow === item.id && this.props.multiple.length > 0) {
      let multiple = [];
      multiple = this.props.multiple.filter((obj) => {
        return obj.id !== item.id;
      });

      itemRows.push(
        multiple.map((item, i) => (
          <tr key={i} className="expanded">
            <td className="expanded"> </td>
            <td className="expanded">
              {Array.isArray(item.pickup_location)
                ? item.pickup_location.map((item, index) => (
                    <p key={index} className="multi">
                      {item.depot}
                    </p>
                  ))
                : [item.pickup_location].map((item, index) => (
                    <p key={index} className="multi">
                      {item.depot}
                    </p>
                  ))}
            </td>
            <td className="expanded">{item.item_name}</td>
            <td className="expanded">{item.quantity}</td>
            <td className="expanded">{item.vehicle}</td>
            <td className="expanded">{item.capacity}</td>
            <td className="expanded">{item.dropoff_location}</td>
            <td className="expanded">
              <FontAwesomeIcon
                icon="circle"
                className={
                  item.approval_state === false ? "pending" : "approved"
                }
              />
              <br />
              {item.approval_state ? "Approved" : "Pending"}
            </td>
            <td className="expanded">
              {item.approval_state === false ? (
                <ClearBtn
                  text="APPROVE"
                  click={(e) => this.openConfirm(e.stopPropagation(), item.id)}
                />
              ) : (
                <ClearBtnDisabled text="APPROVE" />
              )}
            </td>
          </tr>
        ))
      );
    }

    return itemRows;
  }

  render() {
    const {
      show,
      open,
      dash,
      toast,
      id,
      region,
      regionDisplayed,
      approvedId,
      count,
      confirm,
      currentPage,
      resultPerPage,
      previous,
      next,
    } = this.state;

    let allItemRows = [];

    dash.map((item) => {
      const perItemRows = this.renderItem(item);
      allItemRows = allItemRows.concat(perItemRows);
    });

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(count / resultPerPage); i++) {
      pageNumbers.push(i);
    }

    const token = localStorage.getItem("token");
    if (!token) {
      return <Redirect to={"/login"} />;
    }
    return (
      <div id="home">
        <Header />
        <Toast
          show={toast}
          message={`Order Id (${approvedId}) has been approved`}
        />
        <div className="home">
          <div className="top" ref={this.container}>
            <div className="top-left">
              <div className="filter-div" onClick={this.filterClick}>
                <FontAwesomeIcon icon="filter" className="filter" />
                <p className="filter-text">Filter:</p>
              </div>
              <p className="key">
                Region:{" "}
                <span className="value">{regionDisplayed.toUpperCase()}</span>
              </p>
              <p className="key">
                Total Number of Orders: <span className="value">{count}</span>
              </p>
            </div>
            <div className="top-right">
              {/* <div className='search-container'>
                                <FontAwesomeIcon icon="search" className='search-icon' />
                                <input type="text" id="search" name="search" placeholder="Search Orders" className='search' />
                            </div> */}
              <CSVLink data={dash} style={{ textDecoration: "none" }}>
                <SolidBtn text="EXPORT CSV" />
              </CSVLink>
            </div>
            {open && (
              <div className="filter-drop">
                <select
                  className="filter-select"
                  value={this.state.region}
                  onChange={this.handleFilter}
                >
                  <option value="">Region</option>
                  <option value="lg">LG</option>
                  <option value="mb">MB</option>
                  <option value="sw">SW</option>
                  <option value="se">SE</option>
                  <option value="nt">NT</option>
                </select>
                <div className="filter-btns">
                  <ClearBtn text="CLEAR" click={this.filterClose} />
                  <SolidBtn text="APPLY" click={this.filter} />
                </div>
              </div>
            )}
          </div>
          <div className="table-container">
            <table className="table-header">
              <tbody>
                <tr>
                  <th>Transaction ID</th>
                  <th>Pick Up Location</th>
                  <th>Item Name</th>
                  <th>Qty</th>
                  <th>Vehicle Reg No.</th>
                  <th>Vehicle Capacity (Cases)</th>
                  <th>Drop off Location</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </tbody>
            </table>
            {this.props.loading && <Loader />}
            {dash.length < 1 &&
              !this.props.dataFetched &&
              !this.props.loading && (
                <div className="notLoaded">
                  <p>No response</p>
                  <ClearBtn text="RELOAD" click={this.reload} />
                </div>
              )}
            {dash.length < 1 &&
              this.props.dataFetched &&
              !this.props.loading && (
                <div className="empty-container">
                  <img alt="Empty" src={warehouse} className="empty" />
                  <p>No raised orders for this region yet</p>
                  <div className="btn-container">
                    <ClearBtn text="CHECK AGAIN" click={this.reload} />
                  </div>
                </div>
              )}
            {dash.length > 0 &&
              this.props.dataFetched &&
              !this.props.loading && (
                <table className="table-body">
                  <tbody>{allItemRows}</tbody>
                </table>
              )}
          </div>
          {count > 10 && (
            <div className="pagination">
              <p className="count">
                {dash.length} of {count} items
              </p>
              <div className="pagination-btns">
                {previous && (
                  <div
                    className="pagination-single"
                    onClick={this.prevPagination}
                  >
                    {"<"}
                  </div>
                )}
                {pageNumbers.map((number) => {
                  return (
                    <div
                      className={
                        currentPage === number
                          ? "pagination-single current"
                          : "pagination-single"
                      }
                      key={number}
                      id={number}
                      onClick={this.handlePagination}
                    >
                      {number}
                    </div>
                  );
                })}
                {next && (
                  <div
                    className="pagination-single"
                    onClick={this.nextPagination}
                  >
                    {">"}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <Modal
          show={show}
          handleClose={this.closeModal}
          style={"modal-main-wide"}
        >
          <div id="popup">
            <div className="modal-top">
              <p>
                Raised Order With Order ID{" "}
                <span className={"order-id"}>{id}</span>
              </p>
            </div>
            <div className="wide-modal-body">
              <div className="modal-table-container">
                <table className="table-header">
                  <tbody>
                    <tr>
                      <th>Pick Up Location</th>
                      <th>Item Name</th>
                      <th>Qty</th>
                      <th>Vehicle Reg No.</th>
                      <th>Vehicle Capacity (Cases)</th>
                      <th>Drop off Location</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </tbody>
                </table>
                <table className="table-body">
                  <tbody>
                    {this.props.multiple.map((item, i) => (
                      <tr key={i}>
                        <td>
                          {Array.isArray(item.pickup_location)
                            ? item.pickup_location.map((item, index) => (
                                <p key={index} className="multi">
                                  {item.depot}
                                </p>
                              ))
                            : [item.pickup_location].map((item, index) => (
                                <p key={index} className="multi">
                                  {item.depot}
                                </p>
                              ))}
                        </td>
                        <td>{item.item_name}</td>
                        <td>{item.quantity}</td>
                        <td>{item.vehicle}</td>
                        <td>{item.capacity}</td>
                        <td>{item.dropoff_location}</td>
                        <td>
                          <FontAwesomeIcon
                            icon="circle"
                            className={
                              item.approval_state === false
                                ? "pending"
                                : "approved"
                            }
                          />
                          <br />
                          {item.approval_state ? "Approved" : "Pending"}
                        </td>
                        <td>
                          {item.approval_state === false ? (
                            <ClearBtn
                              text="APPROVE"
                              click={(e) =>
                                this.openConfirm(e.stopPropagation(), item.id)
                              }
                            />
                          ) : (
                            <ClearBtnDisabled text="APPROVE" />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* <div className='order-graphic'>
                                <div className='graphic-top'>
                                    <p>Raised Order Details</p>
                                </div>
                                <div className='graphic-body'>
                                    <div className='icon-col'>
                                        <p className='top-text-drop'>1st Pickup</p>
                                        <img alt='icon' src={pickup} />
                                        <p className='bottom-text'>Bayelsa</p>
                                    </div>
                                    <div className='icon-col'>
                                        <p className='top-text-drop'>1st Pickup</p>
                                        <img alt='icon' src={dropoff} />
                                        <p className='bottom-text'>Bayelsa</p>
                                    </div>
                                    <div className='icon-col'>
                                        <p className='top-text-drop'>1st Pickup</p>
                                        <img alt='icon' src={dropoff} />
                                        <p className='bottom-text'>Bayelsa</p>
                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
        </Modal>
        <ConfirmModal
          confirm={confirm}
          id={approvedId}
          yes={this.handleApprove}
          no={this.closeConfirm}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchAllOrders: (region, page) => dispatch(fetchAllOrders(region, page)),
  approveOrder: (id, region) => dispatch(approveOrder(id, region)),
  fetchMultiple: (plate) => dispatch(fetchMultiple(plate)),
});

const mapStateToProps = (state) => ({
  loading: state.dash.loading,
  loadingMultiple: state.dash.loadingMultiple,
  dataFetched: state.dash.dataFetched,
  multiple: state.dash.multiple,
  data: state.dash.data,
  approved: state.dash.data,
  errorMsg: state.dash.errorMsg,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
