import React, { Component } from 'react';
import FleetSettings from './FleetSettings';
import SkuSettings from './SkuSettings';
import Threshold from './Threshold';
import DepotLocation from './DepotLocation';

class ConfigTab extends Component {
    state = {
        active: 1
    }

    handleChange = (num) => {
        this.setState({
            active: num
        })
    }

    render() {
        const { active } = this.state;

        return (
            <div id='settings'>
                <div className='main'>
                    <div className='config-nav'>
                        <p className={active===1?'config-link active':'config-link'} onClick={() => this.handleChange(1)}>Fleets Settings</p>
                        <p className={active===2?'config-link active':'config-link'} onClick={() => this.handleChange(2)}>SKU Settings</p>
                        <p className={active===3?'config-link active':'config-link'} onClick={() => this.handleChange(3)}>Threshold Settings</p>
                        <p className={active===4?'config-link active':'config-link'} onClick={() => this.handleChange(4)}>Depot Location</p>
                    </div>     
                    {
                        active === 1 &&
                        <FleetSettings />
                    }
                    {
                        active === 2 &&
                        <SkuSettings />
                    }
                    {
                        active === 3 &&
                        <Threshold />
                    }
                    {
                        active === 4 &&
                        <DepotLocation />
                    }               
                </div>
            </div>
        )
    }
}

export default ConfigTab;
