import React, { Component } from 'react';
import logo from '../../assets/logo.svg';
import AuthBtn from '../common/AuthBtn';
import success from '../../assets/check-circle.svg';
import left from '../../assets/arrow-left.svg';
import { Link, Redirect } from 'react-router-dom';

class CreateSuccess extends Component {
    render() {
        const token = localStorage.getItem('token');
        if (token) {
            return <Redirect to={"/home"} />;
        }
        return (
            <div id='auth'>
                <div className='form'>
                    <img alt='Logo' src={logo} className='logo' />
                    <div className='form-box'>
                        <img className='success' alt='Success' src={success} />
                        <p className='header'>Thank YOU</p>
                        <div className='form-main'>
                            <p>You have succesfully created your GBNL IO ADMIN account</p>
                            <div className='margin'></div>
                            <Link to="/login" style={{ textDecoration: 'none' }}>
                                <AuthBtn text='Continue to Login' image={<img alt='left' src={left} className='side-img' />} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateSuccess;
