import React from "react";
import styled from "styled-components";
import Img from "../../../assets/empty-list.svg";

const EmptyList = () => {
  return (
    <EmptyListContaoner>
      <div className="empty-image">
        <img src={Img} alt="Empty-List" />
      </div>
      <p>There is no record available now</p>
    </EmptyListContaoner>
  );
};

const EmptyListContaoner = styled.div`
  background-color: #fff;
  padding-bottom: 30px;
  min-height: calc(100vh - 370px);

  .empty-image {
    width: 400px;
    height: 400px;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  p {
    color: #3c3872;
    font-weight: 600;
    text-align: center;
  }
`;

export default EmptyList;
