import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from '../header/Header';
import MyProfile from './MyProfile';
import Password from './Password';
import account from '../../assets/account.svg';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFilter, faSearch, faCircle, faPlus, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(
    faFilter,
    faSearch,
    faCircle,
    faPlus,
    faPencilAlt
)

class Profile extends Component {
    state = {
        active: 1
    }

    handleSwitch = (num) => {
        this.setState({
            active: num
        })
    }

    render() {
        const { active } = this.state;

        return (
            <div id='profile'>
                <Header />
                <div className='profile-body'>
                    <div className='left-menu'>
                        <p className={active===1?'left-menu-link active':'left-menu-link'} onClick={() => this.handleSwitch(1)}>My Profile</p>
                        {/* <p className={active===2?'left-menu-link active':'left-menu-link'} onClick={() => this.handleSwitch(2)}>Change Password</p> */}
                    </div>
                    {
                        active === 1 &&
                        <MyProfile />
                    }
                    {
                        active === 2 &&
                        <Password />
                    }                    
                </div>
            </div>
        )
    }
}

export default Profile;
