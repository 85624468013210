import {
    FETCH_ALL_FLEET_ACTION_TYPES,
    FETCH_ALL_LOCATION_ACTION_TYPES,
    FETCH_ALL_THRESHOLD_ACTION_TYPES,
    FETCH_ALL_SKU_ACTION_TYPES,
    EDIT_THRESHOLD_ACTION_TYPES,
    SEARCH_FLEET_ACTION_TYPES,
    SEARCH_SKU_ACTION_TYPES,
    SEARCH_THRESHOLD_ACTION_TYPES,
    SEARCH_LOCATION_ACTION_TYPES,
    ADD_SKU_ACTION_TYPES,
    DELETE_SKU_ACTION_TYPES,
    ADD_FLEET_ACTION_TYPES,
    EDIT_FLEET_ACTION_TYPES,
    ACTIVATE_FLEET_ACTION_TYPES,
    DELETE_FLEET_ACTION_TYPES,
    ADD_LOCATION_ACTION_TYPES,
    EDIT_LOCATION_ACTION_TYPES,
    DELETE_LOCATION_ACTION_TYPES
} from '../actions/actionTypes'

const {
    FETCH_ALL_FLEET_REQUEST,
    FETCH_ALL_FLEET_FULFILLED,
    FETCH_ALL_FLEET_REJECTED
} = FETCH_ALL_FLEET_ACTION_TYPES;

const {
    FETCH_ALL_LOCATION_REQUEST,
    FETCH_ALL_LOCATION_FULFILLED,
    FETCH_ALL_LOCATION_REJECTED
} = FETCH_ALL_LOCATION_ACTION_TYPES;

const {
    FETCH_ALL_THRESHOLD_REQUEST,
    FETCH_ALL_THRESHOLD_FULFILLED,
    FETCH_ALL_THRESHOLD_REJECTED
} = FETCH_ALL_THRESHOLD_ACTION_TYPES;

const {
    FETCH_ALL_SKU_REQUEST,
    FETCH_ALL_SKU_FULFILLED,
    FETCH_ALL_SKU_REJECTED
} = FETCH_ALL_SKU_ACTION_TYPES;

const {
    EDIT_THRESHOLD_REQUEST,
    EDIT_THRESHOLD_FULFILLED,
    EDIT_THRESHOLD_REJECTED
} = EDIT_THRESHOLD_ACTION_TYPES;

const {
    SEARCH_FLEET_REQUEST,
    SEARCH_FLEET_FULFILLED,
    SEARCH_FLEET_REJECTED
} = SEARCH_FLEET_ACTION_TYPES;

const {
    SEARCH_SKU_REQUEST,
    SEARCH_SKU_FULFILLED,
    SEARCH_SKU_REJECTED
} = SEARCH_SKU_ACTION_TYPES;

const {
    SEARCH_THRESHOLD_REQUEST,
    SEARCH_THRESHOLD_FULFILLED,
    SEARCH_THRESHOLD_REJECTED
} = SEARCH_THRESHOLD_ACTION_TYPES;

const {
    SEARCH_LOCATION_REQUEST,
    SEARCH_LOCATION_FULFILLED,
    SEARCH_LOCATION_REJECTED
} = SEARCH_LOCATION_ACTION_TYPES;

const {
    ADD_SKU_REQUEST,
    ADD_SKU_FULFILLED,
    ADD_SKU_REJECTED
} = ADD_SKU_ACTION_TYPES;

const {
    DELETE_SKU_REQUEST,
    DELETE_SKU_FULFILLED,
    DELETE_SKU_REJECTED
} = DELETE_SKU_ACTION_TYPES;

const {
    ADD_FLEET_REQUEST,
    ADD_FLEET_FULFILLED,
    ADD_FLEET_REJECTED
} = ADD_FLEET_ACTION_TYPES;

const {
    EDIT_FLEET_REQUEST,
    EDIT_FLEET_FULFILLED,
    EDIT_FLEET_REJECTED
} = EDIT_FLEET_ACTION_TYPES;

const {
    ACTIVATE_FLEET_REQUEST,
    ACTIVATE_FLEET_FULFILLED,
    ACTIVATE_FLEET_REJECTED
} = ACTIVATE_FLEET_ACTION_TYPES;

const {
    DELETE_FLEET_REQUEST,
    DELETE_FLEET_FULFILLED,
    DELETE_FLEET_REJECTED
} = DELETE_FLEET_ACTION_TYPES;

const {
    ADD_LOCATION_REQUEST,
    ADD_LOCATION_FULFILLED,
    ADD_LOCATION_REJECTED
} = ADD_LOCATION_ACTION_TYPES;

const {
    EDIT_LOCATION_REQUEST,
    EDIT_LOCATION_FULFILLED,
    EDIT_LOCATION_REJECTED
} = EDIT_LOCATION_ACTION_TYPES;

const {
    DELETE_LOCATION_REQUEST,
    DELETE_LOCATION_FULFILLED,
    DELETE_LOCATION_REJECTED
} = DELETE_LOCATION_ACTION_TYPES;

const initialState = {
    loading: false,
    loadingEdit: false,
    fleet: null,
    location: null,
    threshold: null,
    sku: null,
    editThreshold: false,
    newSku: false,
    deletedSku: false,
    addedFleet: false,
    editedFleet: false,
    activatedFleet: false,
    deletedFleet: false,
    addedLocation: false,
    editedLocation: false,
    deletedLocation: false,
    fulfilled: false
}

const configReducer = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_ALL_FLEET_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case FETCH_ALL_FLEET_FULFILLED:
            return {
                ...state,
                loading: false,
                fulfilled: true,
                fleet: action.payload
            }

        case FETCH_ALL_FLEET_REJECTED:
            return {
                ...state,
                loading: false,
                fulfilled: false,
            }

        case FETCH_ALL_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case FETCH_ALL_LOCATION_FULFILLED:
            return {
                ...state,
                loading: false,
                fulfilled: true,
                location: action.payload
            }

        case FETCH_ALL_LOCATION_REJECTED:
            return {
                ...state,
                loading: false,
                fulfilled: false,            
            }

        case FETCH_ALL_THRESHOLD_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case FETCH_ALL_THRESHOLD_FULFILLED:
            return {
                ...state,
                loading: false,
                fulfilled: true,
                threshold: action.payload
            }

        case FETCH_ALL_THRESHOLD_REJECTED:
            return {
                ...state,
                loading: false,
                fulfilled: false,        
            }

        case FETCH_ALL_SKU_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case FETCH_ALL_SKU_FULFILLED:
            return {
                ...state,
                loading: false,
                fulfilled: true,
                sku: action.payload
            }

        case FETCH_ALL_SKU_REJECTED:
            return {
                ...state,
                loading: false,
                fulfilled: false,
            }

        case EDIT_THRESHOLD_REQUEST:
            return {
                ...state,
                loadingEdit: true,
            }

        case EDIT_THRESHOLD_FULFILLED:
            return {
                ...state,
                loadingEdit: false,
                editThreshold: true
            }

        case EDIT_THRESHOLD_REJECTED:
            return {
                ...state,
                loadingEdit: false,                
            }

        case SEARCH_FLEET_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case SEARCH_FLEET_FULFILLED:
            return {
                ...state,
                loading: false,
                fulfilled: true,
                fleet: action.payload
            }

        case SEARCH_FLEET_REJECTED:
            return {
                ...state,
                loading: false,
                fulfilled: false             
            }

        case SEARCH_SKU_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case SEARCH_SKU_FULFILLED:
            return {
                ...state,
                loading: false,
                fulfilled: true,
                sku: action.payload
            }

        case SEARCH_SKU_REJECTED:
            return {
                ...state,
                loading: false,
                fulfilled: false             
            }

        case SEARCH_THRESHOLD_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case SEARCH_THRESHOLD_FULFILLED:
            return {
                ...state,
                loading: false,
                fulfilled: true,
                threshold: action.payload
            }

        case SEARCH_THRESHOLD_REJECTED:
            return {
                ...state,
                loading: false,
                fulfilled: false             
            }

        case SEARCH_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case SEARCH_LOCATION_FULFILLED:
            return {
                ...state,
                loading: false,
                fulfilled: true,
                location: action.payload
            }

        case SEARCH_LOCATION_REJECTED:
            return {
                ...state,
                loading: false,
                fulfilled: false             
            }

        case ADD_SKU_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case ADD_SKU_FULFILLED:
            return {
                ...state,
                loading: false,
                newSku: true
            }

        case ADD_SKU_REJECTED:
            return {
                ...state,
                loading: false,                
            }

        case DELETE_SKU_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case DELETE_SKU_FULFILLED:
            return {
                ...state,
                loading: false,
                deletedSku: true
            }

        case DELETE_SKU_REJECTED:
            return {
                ...state,
                loading: false,                
            }

        case ADD_FLEET_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case ADD_FLEET_FULFILLED:
            return {
                ...state,
                loading: false,
                addedFleet: true
            }

        case ADD_FLEET_REJECTED:
            return {
                ...state,
                loading: false,                
            }

        case EDIT_FLEET_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case EDIT_FLEET_FULFILLED:
            return {
                ...state,
                loading: false,
                editedFleet: true
            }

        case EDIT_FLEET_REJECTED:
            return {
                ...state,
                loading: false,                
            }

        case ACTIVATE_FLEET_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case ACTIVATE_FLEET_FULFILLED:
            return {
                ...state,
                loading: false,
                activatedFleet: true
            }

        case ACTIVATE_FLEET_REJECTED:
            return {
                ...state,
                loading: false,                
            }

        case DELETE_FLEET_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case DELETE_FLEET_FULFILLED:
            return {
                ...state,
                loading: false,
                deletedFleet: true
            }

        case DELETE_FLEET_REJECTED:
            return {
                ...state,
                loading: false,                
            }

        case ADD_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case ADD_LOCATION_FULFILLED:
            return {
                ...state,
                loading: false,
                addedLocation: true
            }

        case ADD_LOCATION_REJECTED:
            return {
                ...state,
                loading: false,                
            }

        case EDIT_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case EDIT_LOCATION_FULFILLED:
            return {
                ...state,
                loading: false,
                editedLocation: true
            }

        case EDIT_LOCATION_REJECTED:
            return {
                ...state,
                loading: false,                
            }

        case DELETE_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case DELETE_LOCATION_FULFILLED:
            return {
                ...state,
                loading: false,
                deletedLocation: true
            }

        case DELETE_LOCATION_REJECTED:
            return {
                ...state,
                loading: false,                
            }

        default:
            return state;
    }
};

export default configReducer;