import React, { Component } from 'react';
import SolidBtn from '../common/SolidBtn';
import ClearBtn from '../common/ClearBtn';
import Modal from '../common/Modal';
import Loader from '../common/Loader';
import { connect } from "react-redux";
import { fetchAllThreshold, editThreshold, searchThreshold } from "../../actions/config";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFilter, faSearch, faCircle, faPlus, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(
    faFilter,
    faSearch,
    faCircle,
    faPlus,
    faPencilAlt
)

class Threshold extends Component {
    state = {
        show: false,
        threshold: [],
        thresholdAmount: '',
        binMax: '',
        offset: 0,
        searchKey: ''
    }

    async componentDidMount() {
        this.setState({
            offset: 0
        })
        await this.props.fetchAllThreshold(0);
        if (this.props.data) {
            this.setState({
                threshold: this.props.data.data,
            })
        }
    }

    more = async () => {
        await this.setState({
            offset: this.state.offset + 10,
        })
        if(this.state.searchKey !== ''){
            await this.props.searchThreshold(this.state.offset, this.state.searchKey);
        } else {
            await this.props.fetchAllThreshold(this.state.offset);
        }
        if (this.props.fulfilled) {
            return this.setState({
                threshold: this.state.threshold.concat(this.props.data.data),
            })
        }
    }

    reload = () => {
        this.componentDidMount()
    }

    openModal = (a, b, c, d) => {
        this.setState({
            depot: a,
            item_no: b,
            show: true,
            thresholdAmount: c,
            binMax: d
        })
    }

    closeModal = () => {
        this.setState({
            show: false
        })
    }

    handleChange = (name, e) => {
        this.setState({
            [name]: e.target.value,
        });
    };

    handleSearch = async (e) => {
        this.setState({
            searchKey: e.target.value
        })
        if (e.target.value === '') {
            return this.componentDidMount();
        }
        await this.props.searchThreshold(this.state.offset, e.target.value);
        if (this.props.fulfilled) {
            return this.setState({
                threshold: this.props.data.data,
            })
        }
    }

    handleEdit = async () => {
        const { thresholdAmount, item_no, depot, binMax } = this.state;
        if (!thresholdAmount || !item_no || !depot || !binMax) {
            return;
        }
        this.setState({
            show: false
        })
        await this.props.edit({ threshold: JSON.parse(thresholdAmount), bin_max: JSON.parse(binMax), item_no, depot });

        if (this.props.editThreshold) {
            return this.componentDidMount();
        }
    };

    render() {
        const { show, threshold, depot, item_no, thresholdAmount, binMax } = this.state;
        return (
            <div id='settings'>
                <div className='main'>
                    <div className='top-settings'>
                        <div className='top-left'>
                        </div>
                        <div className='top-right'>
                            <div className='search-container'>
                                <FontAwesomeIcon icon="search" className='search-icon' />
                                <input type="text" id="search" name="search" placeholder="Item Name, Item No" className='search' onChange={text => this.handleSearch(text)} />
                            </div>
                        </div>
                    </div>
                    {/* <div style="overflow-x:auto;"> */}
                    <div className='table-container'>
                        <table className='table-header'>
                            <tbody>
                                <tr>
                                    <th>S|N</th>
                                    <th>Depot</th>
                                    <th>Item</th>
                                    <th>Threshold<br />(Days)</th>
                                    <th>Threshold<br />(Quantity)</th>
                                    <th>Bin Max<br />(Days)</th>
                                    <th>Bin Max<br />(Quantity)</th>
                                    <th>Action</th>
                                </tr>
                            </tbody>
                        </table>
                        {
                            this.props.loadingEdit &&
                            <Loader />
                        }
                        {
                            threshold.length > 0 && !this.props.loadingEdit &&
                            <table className='table-body'>
                                <tbody>
                                    {
                                        threshold.map((item, index) =>
                                            <tr key={index}>
                                                <td></td>
                                                <td>{item.Depot}</td>
                                                <td>{item.Item_no}</td>
                                                <td>{item.Threshold}</td>
                                                <td>{Math.round(item.Quantity * 100) / 100}</td>
                                                <td>{item.bin_max ? item.bin_max : 'Not available'}</td>
                                                <td>{item.binMaxQuantity ? Math.round(item.binMaxQuantity * 100) / 100 : 'Not available'}</td>
                                                <td>
                                                    <div className='edit-container' onClick={() => this.openModal(item.Depot, item.Item_no, item.Threshold, item.bin_max)}>
                                                        <FontAwesomeIcon icon='pencil-alt' className='pencil' />
                                                        <p>Edit</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        }
                        {
                            !this.props.loading && threshold.length < 1 &&
                            <div className='notLoaded'>
                                <p>No response</p>
                                <ClearBtn text='RELOAD' click={this.reload} />
                            </div>
                        }
                        {
                            this.props.loading &&
                            <Loader />
                        }
                    </div>
                    {
                        !this.props.loading && threshold.length > 9 && !this.props.loadingEdit &&
                        <div className='more'>
                            {
                                this.props.fulfilled ?
                                    <ClearBtn text='LOAD MORE' click={this.more} /> :
                                    <p>End of list</p>
                            }
                        </div>
                    }
                </div>
                <Modal show={show} handleClose={this.closeModal} style={"modal-main"}>
                    <div id="popup">
                        <div className='modal-top'>
                            <p>Edit Threshold</p>
                        </div>
                        <div className='modal-body'>
                            <p>Fill in the detail below to Edit details of Item number {item_no}</p>
                            <div>
                                <div className='inline'>
                                    <p className='name'>Depot</p>
                                    <p className='desc'>{depot}</p>
                                </div>
                                <div className='inline'>
                                    <p className='name'>Item Number</p>
                                    <p className='desc'>{item_no}</p>
                                </div>
                                <div className='inline'>
                                    <label htmlFor="threshold">Threshold (Days)</label>
                                    <input type="text" id="threshold" name="threshold" value={thresholdAmount} onChange={text => this.handleChange("thresholdAmount", text)} required />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="bin-max">Bin Max (Days)</label>
                                    <input type="text" id="bin-max" name="bin-max" value={binMax} onChange={text => this.handleChange("binMax", text)} required />
                                </div>
                            </div>
                            <div className='btn-container'>
                                <div>
                                </div>
                                <div className='btns'>
                                    <ClearBtn text={'CANCEL'} click={this.closeModal} />
                                    <SolidBtn text={'SAVE'} click={this.handleEdit} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    fetchAllThreshold: offset => dispatch(fetchAllThreshold(offset)),
    edit: editData => dispatch(editThreshold(editData)),
    searchThreshold: (offset, data) => dispatch(searchThreshold(offset, data))
});

const mapStateToProps = state => ({
    loading: state.config.loading,
    loadingEdit: state.config.loadingEdit,
    data: state.config.threshold,
    editThreshold: state.config.editThreshold,
    fulfilled: state.config.fulfilled
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Threshold);
