import React, { Component } from 'react';
import SolidBtn from '../common/SolidBtn';
import ClearBtn from '../common/ClearBtn';
import Modal from '../common/Modal';
import Loader from '../common/Loader';
import { connect } from "react-redux";
import { fetchAllFleet, addFleet, editFleet, activateFleet, deleteFleet, searchFleet } from "../../actions/config";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch, faPencilAlt, faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(
    faSearch,
    faPencilAlt,
    faBan
)

class FleetSettings extends Component {
    state = {
        show: false,
        showEdit: false,
        fleet: [],
        region: '',
        location: '',
        department: '',
        vehicleModel: '',
        vehiclePlateNumber: '',
        capacity: '',
        offset: 0,
        searchKey: ''
    }

    async componentDidMount() {
        this.setState({
            offset: 0
        })
        await this.props.fetchAllFleet(0);
        if (this.props.data) {
            this.setState({
                fleet: this.props.data.data,
            })
        }
    }

    more = async () => {
        this.setState({
            offset: this.state.offset + 10,
        })
        if (this.state.searchKey !== '') {
            await this.props.searchFleet(this.state.offset, this.state.searchKey);
        } else {
            await this.props.fetchAllFleet(this.state.offset);
        }
        if (this.props.fulfilled) {
            return this.setState({
                fleet: this.state.fleet.concat(this.props.data.data),
            })
        }
    }

    reload = () => {
        this.componentDidMount()
    }

    openModal = () => {
        this.setState({
            show: true,
            region: '',
            location: '',
            department: '',
            vehicleModel: '',
            vehiclePlateNumber: '',
            capacity: ''
        })
    }

    openEditModal = (a, b, c, d, e, f) => {
        this.setState({
            showEdit: true,
            region: a,
            location: b,
            department: c,
            vehicleModel: d,
            vehiclePlateNumber: e,
            capacity: f
        })
    }

    closeModal = () => {
        this.setState({
            show: false,
            showEdit: false
        })
    }

    handleChange = (name, e) => {
        this.setState({
            [name]: e.target.value,
        });
    };

    handleAdd = async () => {
        const { region, location, department, vehicleModel, vehiclePlateNumber, capacity } = this.state;
        if (!region || !location || !department || !vehicleModel || !vehiclePlateNumber || !capacity) {
            return;
        }
        this.setState({
            show: false
        })
        await this.props.addFleet({ region, location, department, vehicleModel, vehiclePlateNumber, capacity });

        if (this.props.addedFleet) {
            return this.componentDidMount();
        }
    };

    handleEdit = async () => {
        const { region, location, department, vehicleModel, vehiclePlateNumber, capacity } = this.state;
        if (!region || !location || !department || !vehicleModel || !vehiclePlateNumber || !capacity) {
            return;
        }
        this.setState({
            showEdit: false
        })
        await this.props.editFleet({ region, location, department, vehicleModel, vehiclePlateNumber, capacity }, vehiclePlateNumber);

        if (this.props.editedFleet) {
            return this.componentDidMount();
        }
    };

    handleDelete = async (vehiclePlateNumber) => {
        await this.props.deleteFleet(vehiclePlateNumber);

        if (this.props.deletedFleet) {
            return this.componentDidMount();
        }
    };

    handleActivate = async (data) => {
        await this.props.activateFleet(data)

        if (this.props.activatedFleet) {
            return this.componentDidMount();
        }
    }

    handleSearch = async (e) => {
        this.setState({
            searchKey: e.target.value
        })
        if (e.target.value === '') {
            return this.componentDidMount();
        }
        await this.props.searchFleet(this.state.offset, e.target.value);
        if (this.props.fulfilled) {
            this.setState({
                fleet: this.props.data.data,
            })
        }
    }

    render() {
        const { show, showEdit, fleet, region, location, department, vehicleModel, vehiclePlateNumber, capacity } = this.state;
        return (
            <div id='settings'>
                <div className='main'>
                    <div className='top-settings'>
                        <div className='top-left'>
                            <SolidBtn text='ADD VEHICLE' click={this.openModal} />
                        </div>
                        <div className='top-right'>
                            <div className='search-container'>
                                <FontAwesomeIcon icon="search" className='search-icon' />
                                <input type="text" id="search" name="search" placeholder="Item Name, Item No" className='search' onChange={text => this.handleSearch(text)} />
                            </div>
                        </div>
                    </div>
                    {/* <div style="overflow-x:auto;"> */}
                    <div className='table-container'>
                        <table className='table-header'>
                            <tbody>
                                <tr>
                                    <th>S|N</th>
                                    <th>Region</th>
                                    <th>Vehicle Type</th>
                                    <th>Vehicle Reg No.</th>
                                    <th>Location</th>
                                    <th>Department</th>
                                    <th>Capacity</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </tbody>
                        </table>
                        {
                            fleet.length > 0 &&
                            <table className='table-body'>
                                <tbody>
                                    {
                                        fleet.map((item, i) =>
                                            <tr key={i}>
                                                <td></td>
                                                <td>{item.region}</td>
                                                <td>{item.type}</td>
                                                <td>{item.vehicle}</td>
                                                <td>{item.location}</td>
                                                <td>{item.department}</td>
                                                <td>{item.capacity}</td>
                                                <td>{item.status ? 'Activated' : 'Deactivated'}</td>
                                                <td className='row'>
                                                    <div className='edit-container' onClick={() => this.openEditModal(item.region, item.location, item.department, item.type, item.vehicle, item.capacity)}>
                                                        <FontAwesomeIcon icon='pencil-alt' className='pencil' />
                                                        <p>Edit</p>
                                                    </div>
                                                    {
                                                        item.status ?
                                                            <div className='edit-container' onClick={() => this.handleDelete(item.vehicle)}>
                                                                <FontAwesomeIcon icon='ban' className='pencil' />
                                                                <p>Deactivate</p>
                                                            </div> :
                                                            <div className='edit-container' onClick={() => this.handleActivate(item.vehicle)}>
                                                                <FontAwesomeIcon icon='ban' className='pencil' />
                                                                <p>Activate</p>
                                                            </div>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        }
                        {
                            !this.props.loading && fleet.length < 1 &&
                            <div className='notLoaded'>
                                <p>No response</p>
                                <ClearBtn text='RELOAD' click={this.reload} />
                            </div>
                        }
                        {
                            this.props.loading &&
                            <Loader />
                        }
                    </div>
                    {
                        !this.props.loading && fleet.length > 9 &&
                        <div className='more'>
                            {
                                this.props.fulfilled ?
                                    <ClearBtn text='LOAD MORE' click={this.more} /> :
                                    <p>End of list</p>
                            }
                        </div>
                    }
                </div>
                <Modal show={show} handleClose={this.closeModal} style={"modal-main"}>
                    <div id="popup">
                        <div className='modal-top'>
                            <p>Add a new fleet</p>
                        </div>
                        <div className='modal-body'>
                            <p>Fill in the details below to add a new fleet</p>
                            <div>
                                <div className='inline'>
                                    <label htmlFor="region">Region</label>
                                    <input type="text" id="region" name="region" onChange={text => this.handleChange("region", text)} required /><br />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="location">Location</label>
                                    <input type="text" id="location" name="location" onChange={text => this.handleChange("location", text)} required />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="department">Department</label>
                                    <input type="text" id="department" name="department" onChange={text => this.handleChange("department", text)} required />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="type">Vehicle Type</label>
                                    <input type="text" id="type" name="type" onChange={text => this.handleChange("vehicleModel", text)} required />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="regNo">Vehicle Reg Number</label>
                                    <input type="text" id="regNo" name="regNo" onChange={text => this.handleChange("vehiclePlateNumber", text)} required />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="capacity">Capacity (Cases)</label>
                                    <input type="text" id="capacity" name="capacity" onChange={text => this.handleChange("capacity", text)} required />
                                </div>
                            </div>
                            <div className='btn-container'>
                                <div>
                                </div>
                                <div className='btns'>
                                    <ClearBtn text={'CANCEL'} click={this.closeModal} />
                                    <SolidBtn text={'ADD VEHICLE'} click={this.handleAdd} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal show={showEdit} handleClose={this.closeModal} style={"modal-main"}>
                    <div id="popup">
                        <div className='modal-top'>
                            <p>Edit fleet</p>
                        </div>
                        <div className='modal-body'>
                            <p>Change the details below to edit a fleet</p>
                            <div>
                                <div className='inline'>
                                    <label htmlFor="region">Region</label>
                                    <input type="text" id="region" name="region" value={region} onChange={text => this.handleChange("region", text)} required /><br />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="location">Location</label>
                                    <input type="text" id="location" name="location" value={location} onChange={text => this.handleChange("location", text)} required />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="department">Department</label>
                                    <input type="text" id="department" name="department" value={department} onChange={text => this.handleChange("department", text)} required />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="type">Vehicle Type</label>
                                    <input type="text" id="type" name="type" value={vehicleModel} onChange={text => this.handleChange("vehicleModel", text)} required />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="regNo">Vehicle Reg Number</label>
                                    <input type="text" id="regNo" name="regNo" value={vehiclePlateNumber} onChange={text => this.handleChange("vehiclePlateNumber", text)} required />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="capacity">Capacity (Cases)</label>
                                    <input type="text" id="capacity" name="capacity" value={capacity} onChange={text => this.handleChange("capacity", text)} required />
                                </div>
                            </div>
                            <div className='btn-container'>
                                <div>
                                </div>
                                <div className='btns'>
                                    <ClearBtn text={'CANCEL'} click={this.closeModal} />
                                    <SolidBtn text={'EDIT FLEET'} click={this.handleEdit} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    fetchAllFleet: offset => dispatch(fetchAllFleet(offset)),
    addFleet: data => dispatch(addFleet(data)),
    editFleet: (data, plate) => dispatch(editFleet(data, plate)),
    activateFleet: data => dispatch(activateFleet(data)),
    deleteFleet: data => dispatch(deleteFleet(data)),
    searchFleet: (offset, data) => dispatch(searchFleet(offset, data))
});

const mapStateToProps = state => ({
    loading: state.config.loading,
    data: state.config.fleet,
    addedFleet: state.config.addedFleet,
    editedFleet: state.config.editedFleet,
    activatedFleet: state.config.activatedFleet,
    deletedFleet: state.config.deletedFleet,
    fulfilled: state.config.fulfilled
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FleetSettings);
