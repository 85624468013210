import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(
    faArrowLeft
)

class Modal extends Component{
    container = React.createRef();
    state = {
        open: true
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = event => {
        if (this.container.current && !this.container.current.contains(event.target)) {
            // console.log('clicked outside')
            this.setState({
                open: false,
            });
        }
    };

    render(){
        const { open } = this.state;
        const showHideClassname = this.props.show ? "modal display-block" : "modal display-none";
        return(
            <div className={showHideClassname}>
                <section className={this.props.style} ref={this.props.show ? this.container : null}>
                    <p onClick={this.props.handleClose} className="modal-back"><FontAwesomeIcon icon='arrow-left' className='back' />Back</p>
                    {this.props.children}                    
                </section>
            </div>
        )
    }
}

export default Modal;
