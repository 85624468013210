import React from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

const ContentLayout = ({ children, titleText, subText, component }) => {
  return (
    <ContentWrap>
      <section className="children-node">{children}</section>
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  height: 100vh;

  .header {
    background-color: #fff;
    padding: 10px 30px 5px;
    height: 80px;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;

    .bread-crumbs {
      padding-left: 4px;
      padding-bottom: 4px;
    }
    .crumb {
      font-weight: 500;
      cursor: pointer;
      font-size: 14px;
      background: none;
      border: none;
      padding: 0;

      :not(:first-child) {
        padding-left: 2px;
      }

      :hover .crumb-string {
        text-decoration: underline;
      }
    }

    h1 {
      font-size: 24px;
    }

    p {
      font-size: 14px;
    }
    .title {
      padding-bottom: 8px;
    }
  }
  .children-node {
    overflow: scroll;
  }
`;
export default ContentLayout;
