import React, { Component } from 'react'

class SolidBtn extends Component {

    render() {
        return (
            <div className='solid-btn' onClick={this.props.click}>
                {/* {this.props.image} */}
                {this.props.text}
            </div>
        )
    }
}

export default SolidBtn;
