import React, { Component } from 'react';
import logo from '../../assets/logo.svg';
import AuthBtn from '../common/AuthBtn';
import loader from '../../assets/loader.svg';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { loginWithEmail } from "../../actions/auth";

class Login extends Component {
    state = {
        show: false,
        loading: false,
        email: '',
        password: '',
        empty: false,
        error: false
    }

    show = () => {
        this.setState({
            show: !this.state.show
        })
    }

    handleLogin = () => {
        this.setState({
            loading: !this.state.loading
        })
    }

    handleChange = (name, e) => {
        this.setState({
            [name]: e.target.value,
            error: false,
            empty: false
        });
    };

    handleSubmit = async () => {
        this.setState({
            loading: true,
            empty: false,
            error: false
        })
        const { email, password } = this.state;
        if (email === '' || password === '') {
            return this.setState({
                empty: true,
                loading: false
            })
        }

        await this.props.login({ email:email.toLowerCase(), password });

        if (this.props.error) {
            this.setState({
                error: true
            })
        }
    };

    render() {
        const { show, loading, empty, error } = this.state;
        const token = localStorage.getItem('token');
        if (token) {
            return <Redirect to={"/home"} />;
        }
        return (
            <div id='auth'>
                <div className='form'>
                    <img alt='Logo' src={logo} className='logo' />
                    {
                        error &&
                        <div className='message-container'>
                            <p className="message">Invalid Email/Password</p>
                        </div>
                    }
                    {
                        empty &&
                        <div className='message-container'>
                            <p className="message">Email/Password cannot be empty</p>
                        </div>
                    }
                    <div className='form-box'>
                        <p className='header'>Login</p>
                        <div className='form-main'>
                            <label htmlFor="email">Your Email Address</label>
                            <input type="text" id="email" name="email" onChange={text => this.handleChange("email", text)} required />
                            <label htmlFor="password">Your Password</label>
                            <input type={show ? "text" : "password"} id="password" name="password" onChange={text => this.handleChange("password", text)} required />
                            <div className='check'><input type="checkbox" onClick={this.show} />Show</div>
                            <AuthBtn click={this.handleSubmit} text={!this.props.loading ? "Login" : <img alt='loading' className='loader' src={loader} />} />
                        </div>
                        <Link to="/reset-password" style={{ textDecoration: 'none' }}>
                            <p className='text forgot'>Forget Password?</p>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    login: data => dispatch(loginWithEmail(data))
});

const mapStateToProps = state => ({
    loading: state.auth.loading,
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    error: state.auth.error
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Login));
