import {
  FETCH_ALL_ORDERS_ACTION_TYPES,
  APPROVE_ORDER_ACTION_TYPES,
  FETCH_GENERATED_ORDERS_ACTION_TYPES,
  FETCH_DEPOT_ACTION_TYPES,
  FETCH_MULTIPLE_ACTION_TYPES,
  FETCH_REPORT_ACTION_TYPES,
  FILTER_REPORT_ACTION_TYPES,
  SEARCH_REPORT_ACTION_TYPES,
} from "../actions/actionTypes";

const {
  FETCH_ALL_ORDERS_REQUEST,
  FETCH_ALL_ORDERS_FULFILLED,
  FETCH_ALL_ORDERS_REJECTED,
} = FETCH_ALL_ORDERS_ACTION_TYPES;

const {
  APPROVE_ORDER_REQUEST,
  APPROVE_ORDER_FULFILLED,
  APPROVE_ORDER_REJECTED,
} = APPROVE_ORDER_ACTION_TYPES;

const {
  FETCH_GENERATED_ORDERS_REQUEST,
  FETCH_GENERATED_ORDERS_FULFILLED,
  FETCH_GENERATED_ORDERS_REJECTED,
} = FETCH_GENERATED_ORDERS_ACTION_TYPES;

const { FETCH_DEPOT_REQUEST, FETCH_DEPOT_FULFILLED, FETCH_DEPOT_REJECTED } =
  FETCH_DEPOT_ACTION_TYPES;

const {
  FETCH_MULTIPLE_REQUEST,
  FETCH_MULTIPLE_FULFILLED,
  FETCH_MULTIPLE_REJECTED,
} = FETCH_MULTIPLE_ACTION_TYPES;

const { FETCH_REPORT_REQUEST, FETCH_REPORT_FULFILLED, FETCH_REPORT_REJECTED } =
  FETCH_REPORT_ACTION_TYPES;

const {
  FILTER_REPORT_REQUEST,
  FILTER_REPORT_FULFILLED,
  FILTER_REPORT_REJECTED,
} = FILTER_REPORT_ACTION_TYPES;

const {
  SEARCH_REPORT_REQUEST,
  SEARCH_REPORT_FULFILLED,
  SEARCH_REPORT_REJECTED,
} = SEARCH_REPORT_ACTION_TYPES;

const initialState = {
  loading: false,
  dataFetched: false,
  loadingMultiple: false,
  multiple: [],
  data: null,
  report: null,
  approved: false,
  errorMsg: null,
};

const dashReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_ALL_ORDERS_FULFILLED:
      return {
        ...state,
        loading: false,
        data: action.payload,
        dataFetched: true,
      };

    case FETCH_ALL_ORDERS_REJECTED:
      return {
        ...state,
        loading: false,
        dataFetched: false,
        errorMsg: action.payload.response,
      };

    case FETCH_GENERATED_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_GENERATED_ORDERS_FULFILLED:
      return {
        ...state,
        loading: false,
        data: action.payload,
        dataFetched: true,
      };

    case FETCH_GENERATED_ORDERS_REJECTED:
      return {
        ...state,
        loading: false,
        dataFetched: false,
        errorMsg: action.payload.response,
      };

    case FETCH_DEPOT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_DEPOT_FULFILLED:
      return {
        ...state,
        loading: false,
        data: action.payload,
        dataFetched: true,
      };

    case FETCH_DEPOT_REJECTED:
      return {
        ...state,
        loading: false,
        dataFetched: false,
        errorMsg: action.payload.response,
      };

    case APPROVE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case APPROVE_ORDER_FULFILLED:
      return {
        ...state,
        loading: false,
        approved: true,
      };

    case APPROVE_ORDER_REJECTED:
      return {
        ...state,
        loading: false,
      };

    case FETCH_MULTIPLE_REQUEST:
      return {
        ...state,
        loadingMultiple: true,
      };

    case FETCH_MULTIPLE_FULFILLED:
      return {
        ...state,
        loadingMultiple: false,
        multiple: action.payload,
      };

    case FETCH_MULTIPLE_REJECTED:
      return {
        ...state,
        loadingMultiple: false,
      };

    case FETCH_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_REPORT_FULFILLED:
      return {
        ...state,
        loading: false,
        report: action.payload,
      };

    case FETCH_REPORT_REJECTED:
      return {
        ...state,
        loading: false,
      };

    case FILTER_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FILTER_REPORT_FULFILLED:
      return {
        ...state,
        loading: false,
        report: action.payload,
      };

    case FILTER_REPORT_REJECTED:
      return {
        ...state,
        loading: false,
      };

    case SEARCH_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SEARCH_REPORT_FULFILLED:
      return {
        ...state,
        loading: false,
        report: action.payload,
      };

    case SEARCH_REPORT_REJECTED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default dashReducer;
