import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from '../header/Header';
import account from '../../assets/account.svg';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(
    faPencilAlt
)

class MyProfile extends Component {

    render() {
        return (
            <div className='main'>
                <div className='account'>
                    <div>
                        <img alt='account' src={account} className='icon' />
                        {/* <p className='icon-text'>Upload Image</p> */}
                    </div>
                    {/* <div className='edit-container'>
                        <FontAwesomeIcon icon='pencil-alt' className='pencil' />
                        <p>Edit</p>
                    </div> */}
                </div>
                <div className='profile-form'>
                    <label htmlFor="email">Email</label>
                    <input type="text" id="email" name="email" value={localStorage.getItem('email')} readOnly />
                    {/* <label htmlFor="fullName">Full Name</label>
                    <input type="text" id="fullName" name="fullName" /> */}
                </div>
                <div className='role'>
                    <p className='key'>Role:<span className='value'>{localStorage.getItem('priviledge')}</span></p>
                </div>
            </div>
        )
    }
}

export default MyProfile;
