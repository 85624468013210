import React, { Component } from 'react';
import logo from '../../assets/logo.svg';
import AuthBtn from '../common/AuthBtn';
import loader from '../../assets/loader.svg';
import { Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { resetPassword } from "../../actions/auth";

export class ChangePassword extends Component {
    state = {
        loading: false,
        password1: '',
        password2: '',
        empty: false,
        message: false,
        passwordMessage: false,
        error: false
    }

    handleChange = (name, e) => {
        this.setState({
            [name]: e.target.value,
            passwordMessage: false,
            empty: false,
            message: false,
            error: false       
        });
    };

    handleSubmit = async () => {
        this.setState({
            error: false       
        });
        
        const token = this.props.match.params.token;

        this.setState({
            loading: true
        })
        const { password1, password2 } = this.state;
        if (!password1 || !password2) {
            return this.setState({
                empty: true
            })
        }
        if (password1 !== password2) {
            return this.setState({
                passwordMessage: true,
            })
        }

        await this.props.reset({ password: password1 }, token);
        if (this.props.resetDone){
            return this.setState({
                message: true
            })
        } else {
            return this.setState({
                error: true
            })
        }
    };

    render() {
        const { empty, passwordMessage, message, error } = this.state;
        const token = localStorage.getItem('token');
        if (token) {
            return <Redirect to={"/home"} />;
        }
        return (
            <div id='auth'>
                <div className='form'>
                    <img alt='Logo' src={logo} className='logo' />
                    {
                        passwordMessage &&
                        <div className='message-container'>
                            <p className="message">Passwords must match</p>
                        </div>
                    }
                    {
                        empty &&
                        <div className='message-container'>
                            <p className="message">Password fields cannot be empty</p>
                        </div>
                    }
                    {
                        message &&
                        <div className='message-container'>
                            <p className="message">Password changed. <Link to="/login" style={{ textDecoration: 'none' }}><span className='remember'>Login here</span></Link></p>
                        </div>
                    }
                    {
                        error &&
                        <div className='message-container'>
                            <p className="message">Error changing password</p>
                        </div>
                    }
                    <div className='form-box'>
                        <p className='header'>Change Password</p>
                        <div className='form-main'>
                            <label htmlFor="newpassword">New Password</label>
                            <input type="password" id="newPassword" name="newpassword" onChange={text => this.handleChange("password1", text)} required />
                            <label htmlFor="confirmpassword">Confirm Password</label>
                            <input type="password" id="confirmPassword" name="confirmpassword" onChange={text => this.handleChange("password2", text)} required />
                            <AuthBtn click={this.handleSubmit} text={!this.props.loading ? "Change Password" : <img alt='loading' className='loader' src={loader} />} />
                        </div>
                        <p className='text forgot-reset'>Remember Password? <Link to="/login" style={{ textDecoration: 'none' }}><span className='remember'>Login</span></Link></p>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    reset: (data, token) => dispatch(resetPassword(data, token))
});

const mapStateToProps = state => ({
    loading: state.auth.loading,
    resetDone: state.auth.resetDone,
    message: state.auth.message
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePassword);
