import axios from "axios";

import {
  LOGIN_WITH_EMAIL_ACTION_TYPES,
  FORGOT_PASSWORD_ACTION_TYPES,
  USER_INVITE_ACTION_TYPES,
  ADMIN_INVITE_ACTION_TYPES,
  RESET_PASSWORD_ACTION_TYPES,
  VIEW_USERS_ACTION_TYPES,
  BLOCK_USER_ACTION_TYPES,
  UNBLOCK_USER_ACTION_TYPES,
  CREATE_ACCOUNT_ACTION_TYPES,
  CREATE_ADMIN_ACTION_TYPES,
} from "./actionTypes";

const {
  LOGIN_WITH_EMAIL_REQUEST,
  LOGIN_WITH_EMAIL_FULFILLED,
  LOGIN_WITH_EMAIL_REJECTED,
} = LOGIN_WITH_EMAIL_ACTION_TYPES;

const {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_FULFILLED,
  FORGOT_PASSWORD_REJECTED,
} = FORGOT_PASSWORD_ACTION_TYPES;

const {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_FULFILLED,
  RESET_PASSWORD_REJECTED,
} = RESET_PASSWORD_ACTION_TYPES;

const { USER_INVITE_REQUEST, USER_INVITE_FULFILLED, USER_INVITE_REJECTED } =
  USER_INVITE_ACTION_TYPES;

const { ADMIN_INVITE_REQUEST, ADMIN_INVITE_FULFILLED, ADMIN_INVITE_REJECTED } =
  ADMIN_INVITE_ACTION_TYPES;

const { VIEW_USERS_REQUEST, VIEW_USERS_FULFILLED, VIEW_USERS_REJECTED } =
  VIEW_USERS_ACTION_TYPES;

const {
  CREATE_ACCOUNT_REQUEST,
  CREATE_ACCOUNT_FULFILLED,
  CREATE_ACCOUNT_REJECTED,
} = CREATE_ACCOUNT_ACTION_TYPES;

const { CREATE_ADMIN_REQUEST, CREATE_ADMIN_FULFILLED, CREATE_ADMIN_REJECTED } =
  CREATE_ADMIN_ACTION_TYPES;

const { BLOCK_USER_REQUEST, BLOCK_USER_FULFILLED, BLOCK_USER_REJECTED } =
  BLOCK_USER_ACTION_TYPES;

const { UNBLOCK_USER_REQUEST, UNBLOCK_USER_FULFILLED, UNBLOCK_USER_REJECTED } =
  UNBLOCK_USER_ACTION_TYPES;

const BASE_URL = "https://staging.iogbnl.com/auth";
// const BASE_URL = "http://a6523b24.ngrok.io";

// THUNKS
const loginWithEmail = (data) => {
  console.log(data);
  return async (dispatch) => {
    dispatch(loginWithEmailRequest());
    try {
      const response = await axios.post(`${BASE_URL}/api/user/login`, data);
      const token = `Bearer ${response.data.token}`;
      const user = response.data.payload;
      // save token and user details to local storage
      localStorage.setItem("token", token);
      localStorage.setItem("email", user.email);
      localStorage.setItem("priviledge", user.priviledge);
      return dispatch(loginWithEmailFulfilled(user));
    } catch (e) {
      console.log(e);
      dispatch(loginWithEmailRejected());
    }
  };
};

const forgotPassword = (data) => {
  return async (dispatch) => {
    dispatch(forgotPasswordRequest());
    try {
      const response = await axios.post(`${BASE_URL}/api/user/forgot`, data);
      console.log(response.data);
      return dispatch(forgotPasswordFulfilled(response.data));
    } catch (e) {
      console.log(e);
      dispatch(forgotPasswordRejected());
    }
  };
};

const resetPassword = (data, token) => {
  console.log(data, token);
  return async (dispatch) => {
    dispatch(resetPasswordRequest());
    try {
      const response = await axios.post(
        `${BASE_URL}/api/user/change-password/${token}`,
        data
      );
      console.log(response.data);
      return dispatch(resetPasswordFulfilled(response.data));
    } catch (e) {
      console.log(e);
      dispatch(resetPasswordRejected());
    }
  };
};

const userInvite = (data) => {
  console.log(data);
  return async (dispatch) => {
    dispatch(userInviteRequest());
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/api/user/userInvite`,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log(response.data);
      return dispatch(userInviteFulfilled(response.data));
    } catch (e) {
      console.log(e);
      dispatch(userInviteRejected());
    }
  };
};

const adminInvite = (data) => {
  console.log(data);
  return async (dispatch) => {
    dispatch(adminInviteRequest());
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/api/user/adminInvite`,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log(response.data);
      return dispatch(adminInviteFulfilled(response.data));
    } catch (e) {
      console.log(e);
      dispatch(adminInviteRejected());
    }
  };
};

const viewUsers = () => {
  return async (dispatch) => {
    dispatch(viewUsersRequest());
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${BASE_URL}/api/user/viewUsers`, {
        headers: {
          Authorization: token,
        },
      });
      console.log(response.data);
      return dispatch(viewUsersFulfilled(response.data));
    } catch (e) {
      console.log(e);
      dispatch(viewUsersRejected());
    }
  };
};

const adminViewUsers = () => {
  return async (dispatch) => {
    dispatch(adminViewUsersRequest());
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${BASE_URL}/api/user/adminViewUsers`, {
        headers: {
          Authorization: token,
        },
      });
      return dispatch(adminViewUsersFulfilled(response.data));
    } catch (e) {
      console.log(e);
      dispatch(adminViewUsersRejected());
    }
  };
};

const createAccount = (data, region, urlToken, createdBy, email) => {
  return async (dispatch) => {
    dispatch(createAccountRequest());
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/api/user/create-account/${region}/${urlToken}/${createdBy}/${email}`,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log(response.data);
      return dispatch(createAccountFulfilled(response.data));
    } catch (e) {
      console.log(e);
      dispatch(createAccountRejected(e));
    }
  };
};

const createAdmin = (data, urlToken, email) => {
  return async (dispatch) => {
    dispatch(createAdminRequest());
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/api/user/create/admin/${urlToken}/${email}`,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log(response.data);
      return dispatch(createAdminFulfilled(response.data));
    } catch (e) {
      console.log(e);
      dispatch(createAdminRejected(e));
    }
  };
};

const blockUser = (data) => {
  console.log(data);
  return async (dispatch) => {
    dispatch(blockUserRequest());
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}/api/user/block`, data, {
        headers: {
          Authorization: token,
        },
      });
      console.log(response.data);
      return dispatch(blockUserFulfilled(response.data));
    } catch (e) {
      console.log(e);
      dispatch(blockUserRejected());
    }
  };
};

const unblockUser = (data) => {
  console.log(data);
  return async (dispatch) => {
    dispatch(unblockUserRequest());
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}/api/user/unblock`, data, {
        headers: {
          Authorization: token,
        },
      });
      console.log(response.data);
      return dispatch(unblockUserFulfilled(response.data));
    } catch (e) {
      console.log(e);
      dispatch(unblockUserRejected());
    }
  };
};

// ACTION CREATORS

const loginWithEmailRequest = () => ({
  type: LOGIN_WITH_EMAIL_REQUEST,
});

const loginWithEmailFulfilled = (user) => ({
  type: LOGIN_WITH_EMAIL_FULFILLED,
  payload: user,
});

const loginWithEmailRejected = () => ({
  type: LOGIN_WITH_EMAIL_REJECTED,
});

const forgotPasswordRequest = () => ({
  type: FORGOT_PASSWORD_REQUEST,
});

const forgotPasswordFulfilled = (data) => ({
  type: FORGOT_PASSWORD_FULFILLED,
  payload: data,
});

const forgotPasswordRejected = () => ({
  type: FORGOT_PASSWORD_REJECTED,
});

const resetPasswordRequest = () => ({
  type: RESET_PASSWORD_REQUEST,
});

const resetPasswordFulfilled = (data) => ({
  type: RESET_PASSWORD_FULFILLED,
  payload: data,
});

const resetPasswordRejected = () => ({
  type: RESET_PASSWORD_REJECTED,
});

const userInviteRequest = () => ({
  type: USER_INVITE_REQUEST,
});

const userInviteFulfilled = (data) => ({
  type: USER_INVITE_FULFILLED,
  payload: data,
});

const userInviteRejected = () => ({
  type: USER_INVITE_REJECTED,
});

const adminInviteRequest = () => ({
  type: ADMIN_INVITE_REQUEST,
});

const adminInviteFulfilled = (data) => ({
  type: ADMIN_INVITE_FULFILLED,
  payload: data,
});

const adminInviteRejected = () => ({
  type: ADMIN_INVITE_REJECTED,
});

const viewUsersRequest = () => ({
  type: VIEW_USERS_REQUEST,
});

const viewUsersFulfilled = (data) => ({
  type: VIEW_USERS_FULFILLED,
  payload: data,
});

const viewUsersRejected = () => ({
  type: VIEW_USERS_REJECTED,
});

const createAccountRequest = () => ({
  type: CREATE_ACCOUNT_REQUEST,
});

const createAccountFulfilled = (data) => ({
  type: CREATE_ACCOUNT_FULFILLED,
  payload: data,
});

const createAccountRejected = (data) => ({
  type: CREATE_ACCOUNT_REJECTED,
  payload: data,
});

const createAdminRequest = () => ({
  type: CREATE_ADMIN_REQUEST,
});

const createAdminFulfilled = (data) => ({
  type: CREATE_ADMIN_FULFILLED,
  payload: data,
});

const createAdminRejected = (data) => ({
  type: CREATE_ADMIN_REJECTED,
  payload: data,
});

const adminViewUsersRequest = () => ({
  type: CREATE_ADMIN_REQUEST,
});

const adminViewUsersFulfilled = (data) => ({
  type: CREATE_ADMIN_FULFILLED,
  payload: data,
});

const adminViewUsersRejected = (data) => ({
  type: CREATE_ADMIN_REJECTED,
  payload: data,
});

const blockUserRequest = () => ({
  type: BLOCK_USER_REQUEST,
});

const blockUserFulfilled = (data) => ({
  type: BLOCK_USER_FULFILLED,
  payload: data,
});

const blockUserRejected = () => ({
  type: BLOCK_USER_REJECTED,
});

const unblockUserRequest = () => ({
  type: UNBLOCK_USER_REQUEST,
});

const unblockUserFulfilled = (data) => ({
  type: UNBLOCK_USER_FULFILLED,
  payload: data,
});

const unblockUserRejected = () => ({
  type: UNBLOCK_USER_REJECTED,
});

export {
  loginWithEmail,
  forgotPassword,
  resetPassword,
  userInvite,
  adminInvite,
  viewUsers,
  createAccount,
  blockUser,
  unblockUser,
  adminViewUsers,
  createAdmin,
};
