import React, { Component } from 'react';
import SolidBtn from '../common/SolidBtn';
import ClearBtn from '../common/ClearBtn';
import confirm from '../../assets/confirm.svg';

class ConfirmModal extends Component {
    render() {
        const showHideClassname = this.props.confirm ? "confirmModal display-block" : "confirmModal display-none";
        return (
            <div className={showHideClassname}>
                <section className='modal-main'>
                    <div className='confirm-top'>
                        <img alt='Confirm' src={confirm} />
                        <p>Order Confirmation</p>
                    </div>
                    <div className='confirm-bottom'>
                        <p>Are you sure you want to approve this order with id {this.props.id}?</p>
                        <div className='confirm-btns'>
                            <ClearBtn text='NO' click={this.props.no} />
                            <SolidBtn text='YES' click={this.props.yes} />
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default ConfirmModal;
