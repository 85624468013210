import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from '../header/Header';
import ConfigTab from './ConfigTab';
import SolidBtn from '../common/SolidBtn';
import ClearBtn from '../common/ClearBtn';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFilter, faSearch, faCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(
    faFilter,
    faSearch,
    faCircle,
    faPlus
)

class ConfigSettings extends Component {
    logout = () => {
        localStorage.clear();
        this.props.history.push({
            pathname: '/login',
        })
    }

    render() {
        return (
            <div id='settings'>
                <Header />
                <div className='settings-body'>
                    <div className='left-menu'>
                        <Link to='/users-settings' className='left-menu-link'><p>Users Settings</p></Link>
                        {
                            localStorage.getItem('priviledge') === 'root' &&
                            <Link to='/config-settings' className='left-menu-link active'><p>Configuration Settings</p></Link>
                        }                        
                        <p className='left-menu-link' onClick={this.logout}>Logout</p>
                    </div>
                    <ConfigTab/>
                </div>
            </div>
        )
    }
}

export default ConfigSettings;
