import {
    LOGIN_WITH_EMAIL_ACTION_TYPES,
    FORGOT_PASSWORD_ACTION_TYPES,
    RESET_PASSWORD_ACTION_TYPES,
    USER_INVITE_ACTION_TYPES,
    ADMIN_INVITE_ACTION_TYPES,
    VIEW_USERS_ACTION_TYPES,
    CREATE_ACCOUNT_ACTION_TYPES,
    CREATE_ADMIN_ACTION_TYPES,
    BLOCK_USER_ACTION_TYPES,
    UNBLOCK_USER_ACTION_TYPES
} from '../actions/actionTypes'

const {
    LOGIN_WITH_EMAIL_REQUEST,
    LOGIN_WITH_EMAIL_FULFILLED,
    LOGIN_WITH_EMAIL_REJECTED
} = LOGIN_WITH_EMAIL_ACTION_TYPES;
const {
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_FULFILLED,
    FORGOT_PASSWORD_REJECTED
} = FORGOT_PASSWORD_ACTION_TYPES;

const {
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_FULFILLED,
    RESET_PASSWORD_REJECTED
} = RESET_PASSWORD_ACTION_TYPES;

const {
    USER_INVITE_REQUEST,
    USER_INVITE_FULFILLED,
    USER_INVITE_REJECTED
} = USER_INVITE_ACTION_TYPES;

const {
    ADMIN_INVITE_REQUEST,
    ADMIN_INVITE_FULFILLED,
    ADMIN_INVITE_REJECTED
} = ADMIN_INVITE_ACTION_TYPES;

const {
    VIEW_USERS_REQUEST,
    VIEW_USERS_FULFILLED,
    VIEW_USERS_REJECTED
} = VIEW_USERS_ACTION_TYPES;

const {
    CREATE_ACCOUNT_REQUEST,
    CREATE_ACCOUNT_FULFILLED,
    CREATE_ACCOUNT_REJECTED
} = CREATE_ACCOUNT_ACTION_TYPES;

const {
    CREATE_ADMIN_REQUEST,
    CREATE_ADMIN_FULFILLED,
    CREATE_ADMIN_REJECTED
} = CREATE_ADMIN_ACTION_TYPES;

const {
    BLOCK_USER_REQUEST,
    BLOCK_USER_FULFILLED,
    BLOCK_USER_REJECTED
} = BLOCK_USER_ACTION_TYPES;

const {
    UNBLOCK_USER_REQUEST,
    UNBLOCK_USER_FULFILLED,
    UNBLOCK_USER_REJECTED
} = UNBLOCK_USER_ACTION_TYPES;

const initialState = {
    isAuthenticated: false,
    error: false,
    resetSent: false,
    resetDone: false,
    loading: false,
    loadingInvite: false,
    invited: false,
    user: null,
    allUsers: null,
    usersFetched: false,
    message: '',
    created: false,
    createdAdmin: false,
    blocked: false,
    unblocked: false,
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {

        case LOGIN_WITH_EMAIL_REQUEST:
            return {
                ...state,
                loading: true,
                error: false
            }

        case LOGIN_WITH_EMAIL_FULFILLED:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                user: action.payload
            }

        case LOGIN_WITH_EMAIL_REJECTED:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                error: true
            }

        case FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            }

        case FORGOT_PASSWORD_FULFILLED:
            return {
                ...state,
                loading: false,
                resetSent: true,
                message: action.payload.message
            }

        case FORGOT_PASSWORD_REJECTED:
            return {
                ...state,
                loading: false,
                resetSent: false,
                message: "Sorry No User Found"
            }

        case RESET_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            }

        case RESET_PASSWORD_FULFILLED:
            return {
                ...state,
                loading: false,
                resetDone: true,
                // message: action.payload.message
            }

        case RESET_PASSWORD_REJECTED:
            return {
                ...state,
                loading: false,
                resetDone: false,
                // message: "Sorry No User Found"
            }

        case USER_INVITE_REQUEST:
            return {
                ...state,
                loadingInvite: true
            }

        case USER_INVITE_FULFILLED:
            return {
                ...state,
                loadingInvite: false,
                invited: true,
            }

        case USER_INVITE_REJECTED:
            return {
                ...state,
                loadingInvite: false,
                invited: false,
            }

        case ADMIN_INVITE_REQUEST:
            return {
                ...state,
                loadingInvite: true
            }

        case ADMIN_INVITE_FULFILLED:
            return {
                ...state,
                loadingInvite: false,
                invited: true,
            }

        case ADMIN_INVITE_REJECTED:
            return {
                ...state,
                loadingInvite: false,
                invited: false,
            }

        case VIEW_USERS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case VIEW_USERS_FULFILLED:
            return {
                ...state,
                loading: false,
                allUsers: action.payload,
                usersFetched: true
            }

        case VIEW_USERS_REJECTED:
            return {
                ...state,
                loading: false,
            }

        case CREATE_ACCOUNT_REQUEST:
            return {
                ...state,
                loading: true
            }

        case CREATE_ACCOUNT_FULFILLED:
            return {
                ...state,
                loading: false,
                created: true,
            }

        case CREATE_ACCOUNT_REJECTED:
            return {
                ...state,
                loading: false,
                created: false,
                errorMsg: action.payload.response
            }

            case CREATE_ADMIN_REQUEST:
            return {
                ...state,
                loading: true
            }

        case CREATE_ADMIN_FULFILLED:
            return {
                ...state,
                loading: false,
                createdAdmin: true,
            }

        case CREATE_ADMIN_REJECTED:
            return {
                ...state,
                loading: false,
                createdAdmin: false,
                errorMsg: action.payload.response
            }

        case BLOCK_USER_REQUEST:
            return {
                ...state,
                loading: true
            }

        case BLOCK_USER_FULFILLED:
            return {
                ...state,
                loading: false,
                blocked: true,
            }

        case BLOCK_USER_REJECTED:
            return {
                ...state,
                loading: false,
                blocked: false
            }

        case UNBLOCK_USER_REQUEST:
            return {
                ...state,
                loading: true
            }

        case UNBLOCK_USER_FULFILLED:
            return {
                ...state,
                loading: false,
                unblocked: true,
            }

        case UNBLOCK_USER_REJECTED:
            return {
                ...state,
                loading: false,
                unblocked: false
            }

        default:
            return state;
    }
};

export default authReducer;