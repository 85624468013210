import React, { Component } from 'react'

class AuthBtn extends Component {

    render() {
        return (
            <div className='auth-btn' onClick={this.props.click}>
                {this.props.image}
                {this.props.text}
            </div>
        )
    }
}

export default AuthBtn;
