import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Login from "./components/auth/Login";
import ResetPassword from "./components/auth/ResetPassword";
import ChangePassword from "./components/auth/ChangePassword";
import CreateAccount from "./components/auth/CreateAccount";
import CreateAdmin from "./components/auth/CreateAdmin";
import CreateSuccess from "./components/auth/CreateSuccess";
import Dashboard from "./components/dashboard/Dashboard";
import GeneratedOrder from "./components/generated/GeneratedOrder";
import Report from "./components/report/Report";
import Profile from "./components/profile/Profile";
import UsersSettings from "./components/settings/UsersSettings";
import ConfigSettings from "./components/settings/ConfigSettings";
import { Provider } from "react-redux";
import { ProtectedRoute } from "./components/auth/Authentication";
import "./App.css";
import { configureStore } from "./store/index";
import GeneratedOrders from "./components/generated/GeneratedOrders";

const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <div>
        <Router>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/home" />} />
            <Route path="/login" component={Login} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/change-password/:token" component={ChangePassword} />
            <Route
              path="/create-account/:region/:token/:createdBy"
              component={CreateAccount}
            />
            <Route path="/create/admin/:token" component={CreateAdmin} />
            <Route path="/create-success" component={CreateSuccess} />
            <ProtectedRoute path="/home" component={GeneratedOrders} />
            <ProtectedRoute path="/raised-order" component={Dashboard} />
            <ProtectedRoute path="/report" component={Report} />
            <ProtectedRoute path="/users-settings" component={UsersSettings} />
            <ProtectedRoute
              path="/config-settings"
              component={ConfigSettings}
            />
            <ProtectedRoute path="/my-profile" component={Profile} />
          </Switch>
        </Router>
      </div>
    </Provider>
  );
}

export default App;
