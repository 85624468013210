import React from 'react';
import loader from '../../assets/dark-loader.svg';

class Loader extends React.Component{
    render(){
        const showHideClassname = this.props.show ? "spinner-modal display-block" : "spinner-modal display-none";
        return(
            <div className={'spinner-modal display-block'}>
                <div className='spinner-div'>
                    <img src={loader} alt='Loading' width='70'/>
                </div>
            </div>
        )
    }
}

export default Loader;
