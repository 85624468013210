import React, { Component } from 'react';
import logo from '../../assets/logo.svg';
import loader from '../../assets/loader.svg';
import AuthBtn from '../common/AuthBtn';
import { Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { forgotPassword } from "../../actions/auth";

export class ResetPassword extends Component {
    state = {
        loading: false,
        email: '',
        empty: false,
        message: false
    }

    handleChange = (name, e) => {
        this.setState({
            [name]: e.target.value,
            empty: false,
            message: false
        });
    };

    handleSubmit = async () => {
        this.setState({
            loading: true
        })
        const { email } = this.state;
        if (email === '') {
            return this.setState({
                empty: true,
            })
        }

        await this.props.forgot({ email });
        return this.setState({
            message: true
        })
    };

    render() {
        const { empty, message } = this.state;
        const token = localStorage.getItem('token');
        if (token) {
            return <Redirect to={"/home"} />;
        }
        return (
            <div id='auth'>
                <div className='form'>
                    <img alt='Logo' src={logo} className='logo' />
                    {
                        message &&
                        <div className='message-container'>
                            <p className="message">{this.props.message}</p>
                        </div>
                    }
                    {
                        empty &&
                        <div className='message-container'>
                            <p className="message">Email cannot be empty</p>
                        </div>
                    }
                    <div className='form-box'>
                        <p className='header'>Reset Password</p>
                        <div className='form-main'>
                            <label htmlFor="email">Your Email Address</label>
                            <input type="text" id="email" name="email" onChange={text => this.handleChange("email", text)} required />
                            <AuthBtn click={this.handleSubmit} text={!this.props.loading ? "Send Reset Password Link" : <img alt='loading' className='loader' src={loader} />} />
                        </div>
                        <p className='text forgot-reset'>Remember Password? <Link to="/login" style={{ textDecoration: 'none' }}><span className='remember'>Login</span></Link></p>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    forgot: data => dispatch(forgotPassword(data))
});

const mapStateToProps = state => ({
    loading: state.auth.loading,
    resetSent: state.auth.resetSent,
    message: state.auth.message
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPassword);
