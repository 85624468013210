import React, { Component } from 'react';
import logo from '../../assets/logo.svg';
import AuthBtn from '../common/AuthBtn';
import loader from '../../assets/loader.svg';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { createAdmin } from "../../actions/auth";

class CreateAdmin extends Component {
    state = {
        warning: false,
        email: '',
        choosePassword: '',
        password: '',
        error: false,
        errorMsg: '',
    }

    componentDidMount() {
        this.setState({
            email: this.props.match.params.email
        })
    }

    focus = (e) => {
        if (e.target.value.length < 8) {
            this.setState({
                warning: true
            })
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        })
        if (e.target.name === 'choosepassword' && e.target.value.length > 7) {
            this.setState({
                warning: false,
            })
        } else {
            this.setState({
                warning: true
            })
        }
    }

    change = (name, e) => {
        this.setState({
            [name]: e.target.value,
            // error: false,
            // errorMsg: ''
        });
    };

    handleCreate = async () => {
        this.setState({
            error: false
        })
        const { email, choosepassword, password } = this.state;
        // const regexp = /^[a-zA-Z0-9.-_]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
        // if (!regexp.test(email)) {
        //     return this.setState({
        //         error: true,
        //         errorMsg: 'Invalid Email'
        //     })
        // }
        if (choosepassword !== password) {
            return this.setState({
                error: true,
                errorMsg: 'Passwords do not match'
            })
        }

        if (password === '') {
            return this.setState({
                loading: false,
                error: true,
                errorMsg: 'Password cannot be empty'
            })
        }

        if (choosepassword === password && password.length < 8) {
            return this.setState({
                error: true,
                errorMsg: 'Password too short'
            })
        }

        // const region = this.props.match.params.region;
        const token = this.props.match.params.token;

        await this.props.createAdmin({ password }, token, email);

        if (this.props.createdAdmin) {
            return this.props.history.push('/create-success');
        } else {
            this.setState({
                error: true,
                errorMsg: this.props.errorMsg.data.message
            })
        }
    };


    render() {
        const { warning, error, errorMsg, email } = this.state;
        const token = localStorage.getItem('token');
        if (token) {
            return <Redirect to={"/home"} />;
        }
        return (
            <div id='auth'>
                <div className='form'>
                    <img alt='Logo' src={logo} className='logo' />
                    {
                        error &&
                        <div className='message-container'>
                            <p className="message">{errorMsg}</p>
                        </div>
                    }
                    <div className='form-box'>
                        <p className='header'>Create Your Admin Account</p>
                        <div className='form-main'>
                            <label htmlFor="choosepassword">Choose Password</label>
                            <input
                                type="password"
                                id="choosePassword"
                                name="choosepassword"
                                onFocus={this.focus}
                                onChange={this.handleChange}
                                required
                            />
                            {
                                warning &&
                                <p className="warning">Your password needs to be at least 8 characters long.</p>
                            }
                            <label htmlFor="retypepassword">Retype Password</label>
                            <input type="password" id="retypePassword" name="retypepassword" onChange={text => this.change("password", text)} required />
                            <AuthBtn click={this.handleCreate} text={!this.props.loading ? "Create My Account" : <img alt='loading' className='loader' src={loader} />} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    createAdmin: (data, token, email) => dispatch(createAdmin(data, token, email))
});

const mapStateToProps = state => ({
    loading: state.auth.loading,
    createdAdmin: state.auth.createdAdmin,
    errorMsg: state.auth.errorMsg
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateAdmin));
