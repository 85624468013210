import React, { Component } from 'react';

class Password extends Component {

    render() {
        return (
            <div className='main'>
                <div className='current-container'>
                    <div className='current'>
                        <label htmlFor="currentPassword">Current Password</label>
                        <input type="password" id="currentPassword" name="currentPassword" /><br/>
                        <p className='forgot'>Forgot Password?</p>
                    </div>
                    <div className='edit-container'>
                        <p>Save</p>
                    </div>
                </div>
                <div className='profile-form'>
                    <label htmlFor="newPassword">New Password</label>
                    <input type="password" id="newPassword" name="newPassword" />
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input type="password" id="confirmPassword" name="fullName" />
                </div>
            </div>
        )
    }
}

export default Password;
