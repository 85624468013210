import axios from "axios";

import {
  FETCH_ALL_ORDERS_ACTION_TYPES,
  APPROVE_ORDER_ACTION_TYPES,
  FETCH_MULTIPLE_ACTION_TYPES,
  FETCH_REPORT_ACTION_TYPES,
  FILTER_REPORT_ACTION_TYPES,
  SEARCH_REPORT_ACTION_TYPES,
  FETCH_GENERATED_ORDERS_ACTION_TYPES,
  FETCH_DEPOT_ACTION_TYPES,
} from "./actionTypes";

const {
  FETCH_ALL_ORDERS_REQUEST,
  FETCH_ALL_ORDERS_FULFILLED,
  FETCH_ALL_ORDERS_REJECTED,
} = FETCH_ALL_ORDERS_ACTION_TYPES;

const {
  APPROVE_ORDER_REQUEST,
  APPROVE_ORDER_FULFILLED,
  APPROVE_ORDER_REJECTED,
} = APPROVE_ORDER_ACTION_TYPES;

const {
  FETCH_MULTIPLE_REQUEST,
  FETCH_MULTIPLE_FULFILLED,
  FETCH_MULTIPLE_REJECTED,
} = FETCH_MULTIPLE_ACTION_TYPES;

const { FETCH_REPORT_REQUEST, FETCH_REPORT_FULFILLED, FETCH_REPORT_REJECTED } =
  FETCH_REPORT_ACTION_TYPES;

const {
  FILTER_REPORT_REQUEST,
  FILTER_REPORT_FULFILLED,
  FILTER_REPORT_REJECTED,
} = FILTER_REPORT_ACTION_TYPES;

const {
  FETCH_GENERATED_ORDERS_REQUEST,
  FETCH_GENERATED_ORDERS_FULFILLED,
  FETCH_GENERATED_ORDERS_REJECTED,
} = FETCH_GENERATED_ORDERS_ACTION_TYPES;

const { FETCH_DEPOT_REQUEST, FETCH_DEPOT_FULFILLED, FETCH_DEPOT_REJECTED } =
  FETCH_DEPOT_ACTION_TYPES;

const {
  SEARCH_REPORT_REQUEST,
  SEARCH_REPORT_FULFILLED,
  SEARCH_REPORT_REJECTED,
} = SEARCH_REPORT_ACTION_TYPES;

const BASE_URL = "https://staging.iogbnl.com/dashboard";

// THUNKS
const fetchAllOrders = (region, page) => {
  return async (dispatch) => {
    dispatch(fetchAllOrdersRequest());
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${BASE_URL}/api/v1/dashboard/orders?region=${region}&page=${page}&limit=10`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return dispatch(fetchAllOrdersFulfilled(response.data.data));
    } catch (e) {
      console.log(e);
      dispatch(fetchAllOrdersRejected(e));
    }
  };
};

const approveOrder = (id, region) => {
  return async (dispatch, getState) => {
    dispatch(approveOrderRequest());
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/api/v1/dashboard/approve?order_id=${id}&region=${region}`,
        null,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return dispatch(approveOrderFulfilled());
    } catch (e) {
      console.log(e);
      dispatch(approveOrderRejected());
    }
  };
};

const fetchMultiple = (plate) => {
  return async (dispatch) => {
    dispatch(fetchMultipleRequest());
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${BASE_URL}/api/v1/dashboard/multipleData?vehicle_plate_no=${plate}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log(response.data.data);
      return dispatch(fetchMultipleFulfilled(response.data.data));
    } catch (e) {
      console.log(e);
      dispatch(fetchMultipleRejected());
    }
  };
};

const fetchReport = (page) => {
  return async (dispatch) => {
    dispatch(fetchReportRequest());
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${BASE_URL}/api/v1/dashboard/report?page=${page}&limit=10`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log(response.data.data);
      return dispatch(fetchReportFulfilled(response.data.data));
    } catch (e) {
      console.log(e);
      dispatch(fetchReportRejected());
    }
  };
};

const filterReport = (region, date, page) => {
  console.log(
    `${BASE_URL}/api/v1/dashboard/reportByRegion?region=${region}&date=${date}&page=${page}&limit=10`
  );
  return async (dispatch) => {
    dispatch(filterReportRequest());
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${BASE_URL}/api/v1/dashboard/reportByRegion?region=${region}&date=${date}&page=${page}&limit=10`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log(response.data.data);
      return dispatch(filterReportFulfilled(response.data.data));
    } catch (e) {
      console.log(e);
      dispatch(filterReportRejected());
    }
  };
};

const fetchGeneratedOrders = (
  limit,
  page,
  specificDate,
  startDate,
  endDate,
  region,
  depot
) => {
  return async (dispatch) => {
    var params = {
      ...(Boolean(specificDate || startDate || endDate) && {
        from: specificDate || startDate || endDate,
        to: specificDate || startDate || endDate,
      }),
      ...(Boolean(limit) && { limit }),
      ...(Boolean(page) && { page }),
      ...(Boolean(region && region !== "all") && {
        region: region.toUpperCase(),
      }),
      ...(Boolean(depot && depot !== "all") && { depot }),
    };
    dispatch(fetchGeneratedOrdersRequest());

    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${BASE_URL}/api/v1/dashboard/ungrouped-orders`,
        {
          params,
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch(fetchGeneratedOrdersFulfilled(response.data));
      return response;
    } catch (e) {
      console.log(e);
      dispatch(fetchGeneratedOrdersRejected());
    }
  };
};

const fetchAllDepot = () => {
  return async (dispatch) => {
    dispatch(fetchDepotRequest());
    try {
      const response = await axios.get(`${BASE_URL}/api/v1/dashboard/depots`);
      return dispatch(fetchDepotFulfilled(response.data));
    } catch (e) {
      console.log(e);
      dispatch(fetchDepotRejected(e));
    }
  };
};

const searchReport = (page, data) => {
  return async (dispatch) => {
    dispatch(searchReportRequest());
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${BASE_URL}/api/v1/dashboard/searchReport?page=${page}&limit=10`,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log(response.data.data);
      return dispatch(searchReportFulfilled(response.data.data));
    } catch (e) {
      console.log(e);
      dispatch(searchReportRejected());
    }
  };
};

// ACTION CREATORS

const fetchAllOrdersRequest = () => ({
  type: FETCH_ALL_ORDERS_REQUEST,
});

const fetchAllOrdersFulfilled = (data) => ({
  type: FETCH_ALL_ORDERS_FULFILLED,
  payload: data,
});

const fetchAllOrdersRejected = (data) => ({
  type: FETCH_ALL_ORDERS_REJECTED,
  payload: data,
});

const approveOrderRequest = () => ({
  type: APPROVE_ORDER_REQUEST,
});

const approveOrderFulfilled = () => ({
  type: APPROVE_ORDER_FULFILLED,
});

const approveOrderRejected = () => ({
  type: APPROVE_ORDER_REJECTED,
});

const fetchMultipleRequest = () => ({
  type: FETCH_MULTIPLE_REQUEST,
});

const fetchMultipleFulfilled = (data) => ({
  type: FETCH_MULTIPLE_FULFILLED,
  payload: data,
});

const fetchMultipleRejected = () => ({
  type: FETCH_MULTIPLE_REJECTED,
});

const fetchReportRequest = () => ({
  type: FETCH_REPORT_REQUEST,
});

const fetchReportFulfilled = (data) => ({
  type: FETCH_REPORT_FULFILLED,
  payload: data,
});

const fetchReportRejected = () => ({
  type: FETCH_REPORT_REJECTED,
});

const filterReportRequest = () => ({
  type: FILTER_REPORT_REQUEST,
});

const filterReportFulfilled = (data) => ({
  type: FILTER_REPORT_FULFILLED,
  payload: data,
});

const filterReportRejected = () => ({
  type: FILTER_REPORT_REJECTED,
});

const fetchGeneratedOrdersRequest = () => ({
  type: FETCH_GENERATED_ORDERS_REQUEST,
});

const fetchGeneratedOrdersFulfilled = (data) => ({
  type: FETCH_GENERATED_ORDERS_FULFILLED,
  payload: data,
});

const fetchGeneratedOrdersRejected = () => ({
  type: FETCH_GENERATED_ORDERS_REJECTED,
});

const fetchDepotRequest = () => ({
  type: FETCH_DEPOT_REQUEST,
});

const fetchDepotFulfilled = (data) => ({
  type: FETCH_DEPOT_FULFILLED,
  payload: data,
});

const fetchDepotRejected = () => ({
  type: FETCH_DEPOT_REJECTED,
});

const searchReportRequest = () => ({
  type: SEARCH_REPORT_REQUEST,
});

const searchReportFulfilled = (data) => ({
  type: SEARCH_REPORT_FULFILLED,
  payload: data,
});

const searchReportRejected = () => ({
  type: SEARCH_REPORT_REJECTED,
});

export {
  fetchAllOrders,
  approveOrder,
  fetchMultiple,
  fetchReport,
  filterReport,
  fetchGeneratedOrders,
  fetchAllDepot,
  searchReport,
};
