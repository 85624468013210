export const toCsv = (tableData) => {
  let headerValues = Object.keys(tableData[0]).join(",");
  headerValues = headerValues.replace("createdAt", "date");

  let tableValues = tableData
    .map((el) => {
      let tempObj = { ...el };
      tempObj.createdAt = new Date(tempObj.createdAt)
        .toLocaleString()
        .replace(", ", " ");
      return Object.values(tempObj).join(",");
    })
    .join("\n");

  return `${headerValues}\n${tableValues}`;
};

export const bankStatementToCsv = (bankData) => {
  let headerValues = Object.keys(bankData[0]).join(",");
  //headerValues = headerValues.replace('createdAt', 'date');

  let values = bankData
    .map((el) => {
      let tempObj = { ...el };
      tempObj.ENTRY_DATE = new Date(tempObj.ENTRY_DATE)
        .toLocaleString()
        .replace(", ", " ");
      tempObj.GL_DATE = new Date(tempObj.GL_DATE)
        .toLocaleString()
        .replace(", ", " ");
      tempObj.LCHG_TIME = new Date(tempObj.LCHG_TIME)
        .toLocaleString()
        .replace(", ", " ");
      tempObj.PSTD_DATE = new Date(tempObj.PSTD_DATE)
        .toLocaleString()
        .replace(", ", " ");
      tempObj.RCRE_TIME = new Date(tempObj.RCRE_TIME)
        .toLocaleString()
        .replace(", ", " ");
      tempObj.TRAN_DATE = new Date(tempObj.TRAN_DATE)
        .toLocaleString()
        .replace(", ", " ");
      tempObj.VALUE_DATE = new Date(tempObj.VALUE_DATE)
        .toLocaleString()
        .replace(", ", " ");
      tempObj.VFD_DATE = new Date(tempObj.VFD_DATE)
        .toLocaleString()
        .replace(", ", " ");
      tempObj.PARTICULARS = tempObj.PARTICULARS.replace(",", " ");
      return Object.values(tempObj).join(",");
    })
    .join("\n");

  return `${headerValues}\n${values}`;
};
