import React, { Component } from 'react'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(
    faCheck
)

class Toast extends Component {

    render() {
        const showHideClassname = this.props.show ? "toast slideIn" : "toast slideOut";
        return (
            // <div className='toast slideOut'>
            <div className={showHideClassname}>
                <div className='left'>
                    <FontAwesomeIcon icon="check" className='check' />
                </div>
                <div className='right'>
                    <p>{this.props.message}</p>
                </div>
            </div>
        )
    }
}

export default Toast;
