import React, { Component } from 'react';
import SolidBtn from '../common/SolidBtn';
import ClearBtn from '../common/ClearBtn';
import Modal from '../common/Modal';
import Loader from '../common/Loader';
import { connect } from "react-redux";
import { fetchAllLocation, addLocation, editLocation, deleteLocation, searchLocation } from "../../actions/config";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFilter, faSearch, faCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(
    faFilter,
    faSearch,
    faCircle,
    faPlus
)

class DepotLocation extends Component {
    state = {
        show: false,
        showEdit: false,
        location: [],
        region: '',
        depot: '',
        class_: '',
        latitude: '',
        longitude: '',
        capacity: '',
        space: '',
        offset: 0,
        searchKey: ''
    }

    async componentDidMount() {
        this.setState({
            offset: 0
        })
        await this.props.fetchAllLocation(0);
        if (this.props.data) {
            this.setState({
                location: this.props.data.data,
            })
        }
    }

    more = async () => {
        this.setState({
            offset: this.state.offset + 10,
        })
        if(this.state.searchKey !== ''){
            await this.props.searchLocation(this.state.offset, this.state.searchKey);
        } else {
            await this.props.fetchAllLocation(this.state.offset);
        }
        await this.props.fetchAllLocation(this.state.offset);
        if (this.props.fulfilled) {
            return this.setState({
                location: this.state.location.concat(this.props.data.data),
            })
        } 
    }

    reload = () => {
        this.componentDidMount()
    }

    openModal = () => {
        this.setState({
            show: true,
            region: '',
            depot: '',
            class_: '',
            latitude: '',
            longitude: '',
            capacity: '',
            space: ''
        })
    }

    openEditModal = (a, b, c, d, e, f, g) => {
        this.setState({
            showEdit: true,
            region: a,
            depot: b,
            class_: c,
            latitude: d,
            longitude: e,
            capacity: f,
            space: g
        })
    }

    closeModal = () => {
        this.setState({
            show: false,
            showEdit: false
        })
    }

    handleChange = (name, e) => {
        this.setState({
            [name]: e.target.value,
        });
    };

    handleAdd = async () => {
        const { region, depot, class_, latitude, longitude, capacity, space } = this.state;
        if (!region || !depot || !class_ || !latitude || !longitude || !capacity || !space) {
            return;
        }
        this.setState({
            show: false
        })
        await this.props.addLocation({ region, depot, classes: class_, latitude: JSON.parse(latitude), longitude: JSON.parse(longitude), capacity: JSON.parse(capacity), space_sqm: JSON.parse(space) });

        if (this.props.addedLocation) {
            return this.componentDidMount();
        }
    };

    handleEdit = async () => {
        const { region, depot, class_, latitude, longitude, capacity, space } = this.state;
        if (!region || !depot || !class_ || !latitude || !longitude || !capacity || !space) {
            return;
        }
        this.setState({
            showEdit: false
        })
        await this.props.editLocation({ region, depot, classes: class_, latitude, longitude, capacity, space_sqm: space });

        if (this.props.editedLocation) {
            return this.componentDidMount();
        }
    };

    handleDelete = async (depot) => {
        await this.props.deleteLocation(depot);

        if (this.props.deletedLocation) {
            return this.componentDidMount();
        }
    };

    handleSearch = async (e) => {
        this.setState({
            searchKey: e.target.value
        })
        if (e.target.value === '') {
            return this.componentDidMount();
        }
        await this.props.searchLocation(this.state.offset, e.target.value);
        if (this.props.fulfilled) {
            return this.setState({
                location: this.props.data.data,
            })
        }
    }

    render() {
        const { show, showEdit, location, region, depot, class_, latitude, longitude, capacity, space } = this.state;
        return (
            <div id='settings'>
                <div className='main'>
                    <div className='top-settings'>
                        <div className='top-left'>
                            <SolidBtn text='ADD DEPOT' click={this.openModal} />
                        </div>
                        <div className='top-right'>
                            <div className='search-container'>
                                <FontAwesomeIcon icon="search" className='search-icon' />
                                <input type="text" id="search" name="search" placeholder="Depot, Class" className='search' onChange={text => this.handleSearch(text)} />
                            </div>
                        </div>
                    </div>
                    {/* <div style="overflow-x:auto;"> */}
                    <div className='table-container'>
                        <table className='table-header'>
                            <tbody>
                                <tr>
                                    <th>S|N</th>
                                    <th>Region</th>
                                    <th>Depot</th>
                                    <th>Class</th>
                                    <th>Latitude</th>
                                    <th>Longitude</th>
                                    <th>Capacity<br />(Cases)</th>
                                    <th>Space<br />(Sq|M)</th>
                                    <th>Action</th>
                                </tr>
                            </tbody>
                        </table>
                        {
                            location.length > 0 &&
                            <table className='table-body'>
                                <tbody>
                                    {
                                        location.map((item, index) =>
                                            <tr key={index}>
                                                <td></td>
                                                <td>{item.region}</td>
                                                <td>{item.depot}</td>
                                                <td>{item.class}</td>
                                                <td>{Math.round(item.latitude * 100) / 100}</td>
                                                <td>{Math.round(item.longitude * 100) / 100}</td>
                                                <td>{item.capacity}</td>
                                                <td>{item.space_sqm}</td>
                                                <td className='row'>
                                                    <div className='edit-container' onClick={() => this.openEditModal(item.region, item.depot, item.class, item.latitude, item.longitude, item.capacity, item.space_sqm)}>
                                                        <FontAwesomeIcon icon='pencil-alt' className='pencil' />
                                                        <p>Edit</p>
                                                    </div>
                                                    <div className='edit-container' onClick={() => this.handleDelete(item.depot)}>
                                                        <FontAwesomeIcon icon='ban' className='pencil' />
                                                        <p>Deactivate</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        }
                        {
                            !this.props.loading && location.length < 1 &&
                            <div className='notLoaded'>
                                <p>No response</p>
                                <ClearBtn text='RELOAD' click={this.reload} />
                            </div>
                        }
                        {
                            this.props.loading &&
                            <Loader />
                        }
                    </div>
                    {
                        !this.props.loading && location.length > 9 &&
                        <div className='more'>
                            {
                                this.props.fulfilled ?
                                <ClearBtn text='LOAD MORE' click={this.more} /> :
                                <p>End of list</p>
                            }                            
                        </div>
                    }
                </div>
                <Modal show={show} handleClose={this.closeModal} style={"modal-main"}>
                    <div id="popup">
                        <div className='modal-top'>
                            <p>Add a new depot</p>
                        </div>
                        <div className='modal-body'>
                            <p>Fill in the details below to add a new fleet</p>
                            <div>
                                <div className='inline'>
                                    <label htmlFor="region">Region</label>
                                    <input type="text" id="region" name="region" onChange={text => this.handleChange("region", text)} required /><br />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="depot">Depot</label>
                                    <input type="text" id="depot" name="depot" onChange={text => this.handleChange("depot", text)} required />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="class">Class</label>
                                    <input type="text" id="class" name="class" onChange={text => this.handleChange("class_", text)} required />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="latitude">Latitude</label>
                                    <input type="text" id="latitude" name="latitude" onChange={text => this.handleChange("latitude", text)} required />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="longitude">Longitude</label>
                                    <input type="text" id="longitude" name="longitude" onChange={text => this.handleChange("longitude", text)} required />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="capacity">Capacity (cases)</label>
                                    <input type="text" id="capacity" name="capacity" onChange={text => this.handleChange("capacity", text)} required />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="space">Space (Sq|m)</label>
                                    <input type="text" id="space" name="space" onChange={text => this.handleChange("space", text)} required />
                                </div>
                            </div>
                            <div className='btn-container'>
                                <div>
                                </div>
                                <div className='btns'>
                                    <ClearBtn text={'CANCEL'} click={this.closeModal} />
                                    <SolidBtn text={'ADD DEPOT'} click={this.handleAdd} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal show={showEdit} handleClose={this.closeModal} style={"modal-main"}>
                    <div id="popup">
                        <div className='modal-top'>
                            <p>Edit depot</p>
                        </div>
                        <div className='modal-body'>
                            <p>Fill in the details below to edit selected fleet</p>
                            <div>
                                <div className='inline'>
                                    <label htmlFor="region">Region</label>
                                    <input type="text" id="region" name="region" value={region} onChange={text => this.handleChange("region", text)} required /><br />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="depot">Depot</label>
                                    <input type="text" id="depot" name="depot" value={depot} onChange={text => this.handleChange("depot", text)} required />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="class">Class</label>
                                    <input type="text" id="class" name="class" value={class_} onChange={text => this.handleChange("class_", text)} required />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="latitude">Latitude</label>
                                    <input type="text" id="latitude" name="latitude" value={latitude} onChange={text => this.handleChange("latitude", text)} required />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="longitude">Longitude</label>
                                    <input type="text" id="longitude" name="longitude" value={longitude} onChange={text => this.handleChange("longitude", text)} required />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="capacity">Capacity (cases)</label>
                                    <input type="text" id="capacity" name="capacity" value={capacity} onChange={text => this.handleChange("capacity", text)} required />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="space">Space (Sq|m)</label>
                                    <input type="text" id="space" name="space" value={space} onChange={text => this.handleChange("space", text)} required />
                                </div>
                            </div>
                            <div className='btn-container'>
                                <div>
                                </div>
                                <div className='btns'>
                                    <ClearBtn text={'CANCEL'} click={this.closeModal} />
                                    <SolidBtn text={'EDIT DEPOT'} click={this.handleEdit} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    fetchAllLocation: offset => dispatch(fetchAllLocation(offset)),
    addLocation: data => dispatch(addLocation(data)),
    editLocation: data => dispatch(editLocation(data)),
    deleteLocation: data => dispatch(deleteLocation(data)),
    searchLocation: (offset, data) => dispatch(searchLocation(offset, data))
});

const mapStateToProps = state => ({
    loading: state.config.loading,
    data: state.config.location,
    addedLocation: state.config.addedLocation,
    editedLocation: state.config.editedLocation,
    deletedLocation: state.config.deletedLocation,
    errorMsg: state.config.errorMsg,
    fulfilled: state.config.fulfilled
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DepotLocation);
