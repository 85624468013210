import React, { Component } from 'react'

class ClearBtn extends Component {

    render() {
        return (
            <div className='clear-btn' onClick={this.props.click}>
                {/* {this.props.image} */}
                {this.props.text}
            </div>
        )
    }
}

export default ClearBtn;
