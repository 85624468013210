import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Header from '../header/Header';
import ClearBtn from '../common/ClearBtn';
import SolidBtn from '../common/SolidBtn';
import Modal from '../common/Modal';
import Loader from '../common/Loader';
import Toast from '../common/Toast';
import loader from '../../assets/loader.svg';
import { connect } from "react-redux";
import { userInvite, adminInvite, viewUsers, adminViewUsers, blockUser, unblockUser } from "../../actions/auth";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFilter, faSearch, faCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(
    faFilter,
    faSearch,
    faCircle,
    faPlus
)

class UsersSettings extends Component {
    state = {
        show: false,
        users: [],
        email: '',
        region: '',
        role: '',
        error: false,
        errorMsg: '',
        toast: false,
        blockToast: false,
        emailRecipient: ''
    }

    async componentDidMount() {
        if (localStorage.getItem('priviledge') === 'root') {
            await this.props.viewUsers();
        } else {
            await this.props.adminViewUsers();
        }
        if (this.props.allUsers) {
            this.setState({
                users: this.props.allUsers.finalData
            })
        }
    }

    reload = () => {
        this.componentDidMount()
    }

    openModal = () => {
        this.setState({
            show: true
        })
    }

    closeModal = () => {
        this.setState({
            show: false,
            email: '',
            region: '',
            role: ''
        })
    }

    toastToggle = () => {
        this.setState({
            toast: true
        })
        setTimeout(() => {
            this.setState({
                toast: false
            })
        }, 5000);
    }

    blockToast = () => {
        this.setState({
            blockToast: true
        })
        setTimeout(() => {
            this.setState({
                blockToast: false
            })
        }, 5000);
    }

    handleChange = (name, e) => {
        this.setState({
            [name]: e.target.value,
            error: false,
            errorMsg: ''
        });
    };

    handleBlock = async (email) => {
        await this.props.blockUser({ email });

        if (this.props.blocked) {
            this.setState({
                message: 'blocked'
            })
            this.blockToast();
            return this.componentDidMount();        
        } else {
            this.setState({
                error: true,
                errorMsg: 'An error occured. Try again'
            })
        }
    }

    handleUnblock = async (email) => {
        await this.props.unblockUser({ email });

        if (this.props.unblocked) {
            this.setState({
                message: 'unblocked'
            })
            this.blockToast();
            return this.componentDidMount();            
        } else {
            this.setState({
                error: true,
                errorMsg: 'An error occured. Try again'
            })
        }
    }

    handleInvite = async () => {
        this.setState({
            error: false
        })
        const { email, region, role } = this.state;
        const regexp = /^[a-zA-Z0-9.-_]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
        if (!regexp.test(email)) {
            return this.setState({
                error: true,
                errorMsg: 'Invalid Email'
            })
        }
        if (regexp.test(email) && role==='') {
            return this.setState({
                error: true,
                errorMsg: 'Role cannot be empty'
            })
        }
        if (regexp.test(email) && role==='user' && region==='') {
            return this.setState({
                error: true,
                errorMsg: 'Region cannot be empty'
            })
        }
        if (role === 'admin') {
            this.setState({
                emailRecipient: email
            })
            await this.props.adminInvite({ email });
        }

        if (role === 'user') {
            this.setState({
                emailRecipient: email
            })
            await this.props.userInvite({ email, region });
        }

        if (this.props.invited) {
            this.toastToggle();
            return this.closeModal();            
        } else {
            this.setState({
                error: true,
                errorMsg: 'An error occured. Try again'
            })
        }
    }

    logout = () => {
        localStorage.clear();
        this.props.history.push({
            pathname: '/login',
        })
    }

    render() {
        const { show, users, region, role, email, emailRecipient, error, errorMsg, toast, blockToast, message } = this.state;
        return (
            <div id='settings'>
                <Header />
                <Toast show={toast} message={`Invite has been sent to ${emailRecipient}.`} />
                <Toast show={blockToast} message={`User ${message} successfully`} />
                <div className='settings-body'>
                    <div className='left-menu'>
                        <Link to='/users-settings' className='left-menu-link active'><p>Users Settings</p></Link>
                        {
                            localStorage.getItem('priviledge') === 'root' &&
                            <Link to='/config-settings' className='left-menu-link'><p>Configuration Settings</p></Link>
                        }                        
                        <p className='left-menu-link' onClick={this.logout}>Logout</p>
                    </div>
                    <div className='main'>
                        <div className='top'>
                            <div className='top-left'>
                                <p className='key'>User Settings</p>
                            </div>
                            <div className='top-right'>
                                <ClearBtn text='ADD USER' click={this.openModal} />
                            </div>
                        </div>
                        {/* <div style="overflow-x:auto;"> */}
                        <div className='table-container'>
                            <table className='table-header'>
                                <tbody>
                                    <tr>
                                        <th>S|N</th>
                                        <th>Status</th>
                                        <th>Email Address</th>
                                        <th>Role</th>
                                        <th>Region</th>
                                        <th>Action</th>
                                    </tr>
                                </tbody>
                            </table>
                            {
                                this.props.loading &&
                                <Loader />
                            }
                            {
                                (users.length < 1 && !this.props.usersFetched && !this.props.loading) &&
                                <div className='notLoaded'>
                                    <p>No response</p>
                                    <ClearBtn text='RELOAD' click={this.reload} />
                                </div>
                            }
                            {
                                (users.length < 1 && this.props.usersFetched && !this.props.loading) &&
                                <div className='notLoaded'>
                                    <p>No user added</p>
                                    <ClearBtn text='CHECK AGAIN' click={this.reload} />
                                </div>
                            }
                            {
                                users.length > 0 && !this.props.loading &&
                                <table className='table-body'>
                                    <tbody>
                                        {
                                            users.map((item, i) =>
                                                <tr key={i}>
                                                    <td></td>
                                                    <td><FontAwesomeIcon icon="circle" className={item.status === false ? 'active' : 'inactive'} />{item.status === false? "Active":"Inactive"}</td>
                                                    <td className={'break'}>{item.email}</td>
                                                    <td>{item.priviledges}</td>
                                                    <td>{item.region ? (item.region).toUpperCase() : 'ALL'}</td>
                                                    <td>{item.priviledges === 'root' ? '' : <ClearBtn text={item.status===false?'BLOCK':'UNBLOCK'} click={item.status===false?() => this.handleBlock(item.email): () => this.handleUnblock(item.email)} />}</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            }
                        </div>
                        {/* <div className='pagination'>
                            <p className='count'>12 of 50 items</p>
                            <div className='pagination-btns'>
                                <div className='pagination-single current'>1</div>
                                <div className='pagination-single'>2</div>
                                <div className='pagination-single'>3</div>
                                <div className='pagination-single'>...</div>
                                <div className='pagination-single'>></div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <Modal show={show} handleClose={this.closeModal} style={'modal-main'}>
                    <div id="popup">
                        <div className='modal-top'>
                            <p>Invite user</p>
                            {
                                error &&
                                <div className='message-container'>
                                    <p className="message">{errorMsg}</p>
                                </div>
                            }
                        </div>
                        <div className='modal-body'>
                            <p>Fill in the details below to invite a new user</p>
                            <div>
                                <div className='inline'>
                                    <label htmlFor="email">Email Address</label>
                                    <input type="text" id="email" name="email" value={email} onChange={text => this.handleChange("email", text)} /><br />
                                </div>
                                <div className='inline'>
                                    <label htmlFor="role">Role</label>
                                    {/* <input type="text" id="role" name="role" /> */}
                                    <select className='filter-select' value={role} onChange={text => this.handleChange("role", text)}>
                                        <option value="">Select..</option>
                                        <option value="admin">Admin</option>
                                        <option value="user">User</option>
                                    </select>
                                </div>
                                {
                                    this.state.role === 'user' &&
                                    <div className='inline'>
                                        <label htmlFor="region">Region</label>
                                        {/* <input type="text" id="region" name="region" /> */}
                                        <select className='filter-select' value={region} onChange={text => this.handleChange("region", text)}>
                                            <option value="">Select..</option>
                                            <option value="lg">LG</option>
                                            <option value="mb">MB</option>
                                            <option value="sw">SW</option>
                                            <option value="se">SE</option>
                                            <option value="nt">NT</option>
                                        </select>
                                    </div>
                                }
                            </div>
                            <div className='btn-container'>
                                <div>
                                </div>
                                <div className='btns'>
                                    <ClearBtn text={'CANCEL'} click={this.closeModal} />
                                    <SolidBtn click={this.handleInvite} text={!this.props.loadingInvite ? 'SEND INVITE' : <img alt='loading' className='loader' src={loader} />} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    viewUsers: () => dispatch(viewUsers()),
    adminViewUsers: () => dispatch(adminViewUsers()),
    userInvite: data => dispatch(userInvite(data)),
    adminInvite: data => dispatch(adminInvite(data)),
    blockUser: email => dispatch(blockUser(email)),
    unblockUser: email => dispatch(unblockUser(email))
});

const mapStateToProps = state => ({
    loading: state.auth.loading,
    loadingInvite: state.auth.loadingInvite,
    allUsers: state.auth.allUsers,
    usersFetched: state.auth.usersFetched,
    invited: state.auth.invited,
    blocked: state.auth.blocked,
    unblocked: state.auth.unblocked
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersSettings));
