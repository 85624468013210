import React from "react";
import styled from "styled-components";

export const TableSkeleton = () => {
  return (
    <Skeleton>
      <div className="flex template bg-skeleton">
        <div className="bg-skeleton"></div>
        <div className="flex btn-skeleton">
          <span className="bg-skeleton"></span>
          <span className="bg-skeleton"></span>
          <span className="bg-skeleton"></span>
        </div>
      </div>
      <div className="flex table-head-skeleton">
        <span className="bg-skeleton"></span>
        <span className="bg-skeleton"></span>
        <span className="bg-skeleton"></span>
      </div>
      <div className="table-skeleton">
        {Array.from(Array(8)).map((row, index) => (
          <div className="flex table-row" key={index}>
            <span className="bg-skeleton"></span>
            <span className="bg-skeleton"></span>
            <span className="bg-skeleton"></span>
            <span className="bg-skeleton"></span>
          </div>
        ))}
      </div>
    </Skeleton>
  );
};

const Skeleton = styled.div`
  animation: skeleton-loading 1s linear infinite alternate;
  .template {
    border-radius: 6px;
    padding: 1em 4em;
    align-items: center;
    justify-content: space-between;
    background-color: hsla(var(--clr-light));
  }

  .template > *:first-child {
    border-radius: 6px;
    width: 21em;
    height: 1.5em;
  }

  .btn-skeleton {
    align-items: center;
  }

  .btn-skeleton > * {
    border-radius: 6px;
  }

  .btn-skeleton > *:first-child {
    background: linear-gradient(
      90deg,
      #f1efef -24.18%,
      #f9f8f8 50.26%,
      #e7e5e5 114.84%
    );
    width: 12px;
    height: 11px;
  }

  .btn-skeleton > *:nth-child(2) {
    background: linear-gradient(
      90deg,
      #f1efef -24.18%,
      #f9f8f8 50.26%,
      #e7e5e5 114.84%
    );
    width: 31px;
    height: 18px;
  }

  .btn-skeleton > *:nth-child(3) {
    background: linear-gradient(
      90deg,
      #f1efef -24.18%,
      #f9f8f8 50.26%,
      #e7e5e5 114.84%
    );
    width: 40px;
    height: 18px;
  }

  .table-head-skeleton {
    margin-top: 1em;
    background-color: hsla(var(--clr-light));
    padding: 1em 3em;
    justify-content: space-between;
    align-items: center;
  }

  .table-head-skeleton > * {
    width: 65px;
    height: 18px;
    border-radius: 6px;
  }
  .table-skeleton {
  }

  .table-row {
    padding: 0.5em 3em;
    background: hsla(var(--clr-light));
    justify-content: space-between;
    align-items: center;
  }

  .table-row > * {
    border-radius: 6px;
  }

  .table-row > *:where(:not(:last-child)) {
    width: 79px;
    height: 18px;
  }

  .table-row > *:last-child {
    width: 60px;
    height: 37px;
  }

  @keyframes skeleton-loading {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }
`;
